import {
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  ChangeEventHandler,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AcceptRejectIconButtons } from './AcceptRejectIconButtons';
import { InputProps } from './Input';
import {
  validationCompose,
  requiredValidator,
} from './utils/editable-field-utils';
import { UseEditableCellProps, useEditableCell } from './utils/useEditableCell';
import clsx from 'clsx';
import { Truncate } from './Truncate';

export type EditableInputCellProps = UseEditableCellProps<string> & {
  placeholder?: string;
  withAcceptButtons?: boolean;
  type?: InputProps['type'];
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  textWhenNotEditable?: boolean;
  tooltip?: string | React.ReactNode;
  onEditChange?: (editMode: boolean) => void;
  className?: string;
};

export function EditableInputCell({
  value: originalValue,
  disabled,
  readonly,
  required,
  validate,
  onChange,
  onEditChange,
  withAcceptButtons,
  textWhenNotEditable = false,
  tooltip = originalValue,
  className,
  ...inputProps
}: EditableInputCellProps) {
  const onEditChangeRef = useRef(onEditChange);
  onEditChangeRef.current = onEditChange;

  const inputValidate = useCallback(
    (value) => {
      return validationCompose(value, required && requiredValidator, validate);
    },
    [required, validate]
  );

  const {
    handleChange,
    handleClose,
    isChanged,
    error,
    handleSave,
    value,
    valueRef,
    saving,
    onFocus,
    onBlur,
  } = useEditableCell({
    value: originalValue,
    validate: inputValidate,
    onChange,
  });
  const textRef = useRef(null);

  const handleOnSave = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      event?.preventDefault();
      event?.stopPropagation();
      await handleSave(valueRef.current);
      setIsEditable(false);
      onEditChange?.(false);
    },
    [handleSave, onEditChange, valueRef]
  );

  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const updateValue = e.target.value || undefined;
      handleChange(updateValue);
    },
    [handleChange]
  );
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (isEditable && textRef.current)
      (textRef.current as HTMLInputElement).focus();
  }, [isEditable]);

  useEffect(() => {
    return () => onEditChangeRef.current?.(false);
  }, []);

  return !textWhenNotEditable || isEditable ? (
    <form className="relative" onSubmit={handleOnSave}>
      {saving && <LinearProgress className=" absolute top-0 inset-x-0" />}
      <TextField
        inputRef={textRef}
        onFocus={onFocus}
        onBlur={async () => {
          onBlur();
          await handleOnSave();
        }}
        id="outlined-basic"
        error={!!error}
        size="small"
        variant="outlined"
        required={required}
        disabled={disabled}
        value={value || ''}
        className="w-full border-none"
        onChange={handleOnChange}
        inputProps={inputProps}
        InputProps={{
          className: 'pr-0 !h-9',
          readOnly: readonly,
          endAdornment: withAcceptButtons && (
            <InputAdornment position="end">
              {isChanged && !saving ? (
                <AcceptRejectIconButtons
                  submit
                  disabled={!!error}
                  onReject={handleClose}
                />
              ) : (
                <div className="w-2" />
              )}
            </InputAdornment>
          ),
        }}
      />
    </form>
  ) : (
    <Tooltip title={tooltip ?? ''} arrow className="flex-1">
      <div
        className={clsx('flex w-full', className, !value && 'text-gray-400')}
        onDoubleClick={() => {
          setIsEditable(true);
          onEditChange?.(true);
        }}
      >
        <Truncate value={value || 'N/A'} />
      </div>
    </Tooltip>
  );
}
