import { useCurrentProject } from '../core/CurrentProjectContext';

import { MainNavBar } from './MainNavBar';
import { useServerWarmup } from '../core/useServerWarmup';
import { VersionControlWithDrawer } from '../version-control/VersionControlWithDrawer';
import { VersionControlPanelProvider } from '../core/VersionControlPanelContext';
import { VersionControlProvider } from '../core/VersionControlContext';
import { NetworkMapProvider } from '../core/NetworkMapContext';
import { NetworkWizardContextProvider } from '../network-editor/wizard/NetworkWizardContext';
import { ProjectCardTabs } from './ProjectCardTabs';
import { useLocalStorage } from '../core/useLocalStorage';
import { calcDefaultVersionIdFromLocalStorageKey } from './ProjectPageLoader';
import { useEffect } from 'react';
import { DashboardAndNetworkTabsLoader } from './DashboardAndNetworkTabsLoader';

export function ProjectPage(): JSX.Element {
  const { currentProjectId, currentVersionId } = useCurrentProject();

  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const [_, setDefaultVersionId] = useLocalStorage(
    calcDefaultVersionIdFromLocalStorageKey(currentProjectId),
    ''
  );

  useEffect(() => {
    setDefaultVersionId(currentVersionId);
  }, [currentVersionId, setDefaultVersionId]);

  useServerWarmup();

  return (
    <VersionControlProvider>
      <NetworkMapProviderWrapper />
    </VersionControlProvider>
  );
}

function NetworkMapProviderWrapper() {
  const key = useCurrentProject().currentVersionId;

  return (
    <VersionControlPanelProvider>
      <NetworkMapProvider key={key}>
        <NetworkWizardContextProvider>
          <div className="flex flex-col w-screen h-screen overflow-hidden">
            <MainNavBar>
              <ProjectCardTabs />
            </MainNavBar>

            <main className="flex flex-1 relative overflow-x-auto overflow-y-hidden flex-row">
              <VersionControlWithDrawer />
              <DashboardAndNetworkTabsLoader />
            </main>
          </div>
        </NetworkWizardContextProvider>
      </NetworkMapProvider>
    </VersionControlPanelProvider>
  );
}
