export const REFRESH_INTERVALS = {
  charts: 10_000, // 10 seconds
  projects: 30_000, // 30 seconds
  dashletFields: 60_000, // 1 minute
  insights: 300_000, // 5 minute
  modelMetricNames: 600_000, //10 minutes
  datasets: 300_000, // 5 minute
  datasetVersions: 300_000, // 5 minute
  versions: 10_000, // 15 seconds
  scatterVisualizationsMap: 10_000, // 10 seconds
  slimVisualizations: 30_000, // 30 seconds
  dashboards: 60_000, // 1 minute
  archivedInsights: 60_000, // 1 minute
  sessionEpochs: 5_000, // 5 seconds
  jobLogs: 5_000, // 5 seconds
};

export enum CacheKey {
  NOTIFICATIONS = 'use-notifications',
  DATASETS = 'use-datasets',
  DATASET_VERSIONS = 'use-datasets-versions-',
  INSIGHTS = 'insights',
  ISSUES = 'issues',
  EXPORTED_MODELS = 'exported-models',
  USERS = 'users',
  JOB_LIST = 'use-job-list',
  VERSION_CONTROL = 'version-control',
  RECENT_MODELS = 'recent-models',
  DASHLET_FIELDS = 'dashlet_fields',
  MODEL_METRC_NAMES = 'model-matric-names',
  JOB_LOGS = 'job-logs',
  STATISTICS = 'statistics',
  TEAMS = 'teams',
  PROJECTS = 'projects',
  ALL_USERS = 'allusers',
  MODEL_TESTS = 'modeltests',
  MODEL_TEST_RESULTS = 'modeltestresults',
  MACHINE_TYPES = 'machine-types',
  ENVIRONMENT_INFO = 'environment-info',
  FULL_VISUALIZATION = 'full-visualization',
  SESSION_VISUALIZATIONS = 'session-visualizations',
  FETCH = 'fetch',
}
