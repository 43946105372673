import { useEffect, useRef, useState } from 'react';

export function useLazyLoad<Item>(data: Item[], loadBulkSize = 20) {
  const [visibleData, setVisibleData] = useState<Item[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLTableRowElement | null>(null);

  useEffect(() => {
    setVisibleData(data.slice(0, loadBulkSize));
  }, [data, loadBulkSize]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting && visibleData.length < data.length) {
        setVisibleData((prev) => [
          ...prev,
          ...data.slice(prev.length, prev.length + loadBulkSize),
        ]);
      }
    });
    if (lastElementRef.current)
      observer.current.observe(lastElementRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [visibleData, data, loadBulkSize]);

  return { visibleData, lastElementRef };
}
