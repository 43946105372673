import {
  CustomLayerData,
  CustomLayerDetails,
} from '../../layer-details/CustomLayerDetails';
import { CustomLossDetails } from '../../layer-details/CustomLossDetails';
import { DatasetGroundTruth } from '../../layer-details/GroundTruth';
import { InputDetails } from '../../layer-details/InputDetails';
import { InputsDetails } from '../../layer-details/InputsDetails';
import { MetricDetails } from '../../layer-details/MetricDetails';
import { TFOpLambdaJsonPreviewDetails } from '../../layer-details/TFOpLambdaJsonPreviewDetails';
import { VisualizerDetails } from '../../layer-details/VisualizerDetails';
import { isLossNode } from '../graph-calculation/utils';
import { NodeErrorMsg } from '../wizard/errors';
import { GraphErrorKind } from '../wizard/types';
import { EntityDescriptorWithoutType, EntityDescriptor } from './types';
import {
  updateStateIfPropNotExisted,
  setOrRemoveErrorFromNodeStates,
  hasNodeType,
  updateStateIfUserUniqueNameIsInvalid,
  areAllInputsConnected,
  getNodeDataDisplayName,
  buildGetNodeDataDisplayNameByProp,
} from './utils';

export type NodeDescriptorName =
  | 'Dataset'
  | 'GroundTruth'
  | 'DatasetOutput'
  | 'Visualizer'
  | 'Metric'
  | 'CustomLoss'
  | 'CustomLayer'
  | 'TFOpLambda'
  | 'SlicingOpLambda'
  | 'Input';

const SELECTED_GROUND_TRUTH_NAME = 'output_name';
const NOT_ALL_INPUTS_CONNECTED_MSG = 'Some inputs are not connected';

const DatasetDescriptor: EntityDescriptorWithoutType<'Dataset'> = {
  name: 'Dataset',
  colorTheme: 'dataset',
  detailsTitle: 'INPUTS',
  getDisplayName() {
    return 'Inputs';
  },
  CustomDetails: InputsDetails,
};

const GroundTruthDescriptor: EntityDescriptorWithoutType<'GroundTruth'> = {
  name: 'GroundTruth',
  colorTheme: 'groundTruth',
  detailsTitle: 'GROUNDTRUTH',
  getDisplayName: buildGetNodeDataDisplayNameByProp(SELECTED_GROUND_TRUTH_NAME),
  CustomDetails: DatasetGroundTruth,
  updateState: (node, { nodes, setNodeStates, connectionsByOutputId }) => {
    setNodeStates((current) => {
      const updatedNodeStates = updateStateIfPropNotExisted(
        node,
        SELECTED_GROUND_TRUTH_NAME,
        'GroundTruth',
        current
      );
      if (!node.data[SELECTED_GROUND_TRUTH_NAME]) {
        return updatedNodeStates;
      }

      const hasLossOutput = hasNodeType({
        node,
        nodes,
        connectionsByOutputId,
        predicate: isLossNode,
      });

      return setOrRemoveErrorFromNodeStates(
        hasLossOutput,
        {
          type: GraphErrorKind.node,
          nodeId: node.id,
          msg: NodeErrorMsg.GTHasntLoss,
        },
        node.id,
        updatedNodeStates
      );
    });
  },
};
const InputDescriptor: EntityDescriptorWithoutType<'Input'> = {
  name: 'Input',
  colorTheme: 'input',
  detailsTitle: 'Input',
  getDisplayName: buildGetNodeDataDisplayNameByProp(SELECTED_GROUND_TRUTH_NAME),
  CustomDetails: InputDetails,
  updateState: (node, { setNodeStates }) => {
    setNodeStates((current) => {
      return updateStateIfPropNotExisted(
        node,
        SELECTED_GROUND_TRUTH_NAME,
        'Input',
        current
      );
    });
  },
};
const DatasetOutputDescriptor: EntityDescriptorWithoutType<'DatasetOutput'> = {
  ...GroundTruthDescriptor,
  name: 'DatasetOutput',
};

const VisualizerDescriptor: EntityDescriptorWithoutType<'Visualizer'> = {
  name: 'Visualizer',
  colorTheme: 'visualizer',
  detailsTitle: 'VISUALIZER DETAILS',
  CustomDetails: VisualizerDetails,
  getDisplayName: getNodeDataDisplayName,
  updateState: (node, { nodes, setNodeStates, connectionsByInputId }) => {
    updateStateIfUserUniqueNameIsInvalid(node, { nodes, setNodeStates });

    setNodeStates((current) => {
      const allConnected = areAllInputsConnected({
        node,
        connectionsByInputId,
        componentName: 'Visualizer',
      });

      return setOrRemoveErrorFromNodeStates(
        allConnected,
        {
          type: GraphErrorKind.nodeInput,
          msg: NOT_ALL_INPUTS_CONNECTED_MSG,
          nodeId: node.id,
        },
        node.id,
        current
      );
    });

    setNodeStates((current) => {
      return updateStateIfPropNotExisted(
        node,
        'visualizer_name',
        'Visualizer',
        current
      );
    });
  },
};

const CustomMetricDescriptor: EntityDescriptorWithoutType<'Metric'> = {
  name: 'Metric',
  colorTheme: 'metric',
  detailsTitle: 'CUSTOM METRIC DETAILS',
  CustomDetails: MetricDetails,
  getDisplayName: getNodeDataDisplayName,
  updateState: (node, { nodes, setNodeStates, connectionsByInputId }) => {
    updateStateIfUserUniqueNameIsInvalid(node, { nodes, setNodeStates });

    setNodeStates((current) => {
      const allConnected = areAllInputsConnected({
        node,
        connectionsByInputId,
        componentName: 'Metric',
      });

      return setOrRemoveErrorFromNodeStates(
        allConnected,
        {
          type: GraphErrorKind.nodeInput,
          msg: NOT_ALL_INPUTS_CONNECTED_MSG,
          nodeId: node.id,
        },
        node.id,
        current
      );
    });

    setNodeStates((current) => {
      return updateStateIfPropNotExisted(
        node,
        'metric_name',
        'Metric',
        current
      );
    });
  },
};

const CustomLossDescriptor: EntityDescriptorWithoutType<'CustomLoss'> = {
  name: 'CustomLoss',
  detailsTitle: 'CUSTOM LOSS DETAILS',
  colorTheme: 'customLoss',
  CustomDetails: CustomLossDetails,
  getDisplayName: getNodeDataDisplayName,
  updateState: (node, ctx) => {
    ctx.setNodeStates((current) =>
      updateStateIfPropNotExisted(node, 'name', 'LossFunction', current)
    );
    updateStateIfUserUniqueNameIsInvalid(node, ctx);
  },
};

const CUSTOM_LAYER_SELECTED_NAME = 'selected';

const CustomLayerDescriptor: EntityDescriptorWithoutType<'CustomLayer'> = {
  name: 'CustomLayer',
  detailsTitle: 'CUSTOM LAYER DETAILS',
  colorTheme: 'layer',
  CustomDetails: CustomLayerDetails,
  getDisplayName: buildGetNodeDataDisplayNameByProp(CUSTOM_LAYER_SELECTED_NAME),
  updateState: (node, ctx) => {
    const {
      selected,
      type: _type,
      ...customLayerProps
    } = node.data as CustomLayerData;
    ctx.setNodeStates((current) => {
      const updatedNodeStates = setOrRemoveErrorFromNodeStates(
        !!selected,
        {
          type: GraphErrorKind.nodeAttr,
          msg: 'Please select custom layer',
          nodeId: node.id,
          attrName: CUSTOM_LAYER_SELECTED_NAME,
        },
        node.id,
        current
      );

      const someArgIsUndefined = Object.values(customLayerProps).some(
        (val) => val === undefined
      );

      return setOrRemoveErrorFromNodeStates(
        someArgIsUndefined,
        {
          type: GraphErrorKind.nodeAttr,
          msg: 'All custom layer properties are required',
          nodeId: node.id,
          attrName: 'args',
        },
        node.id,
        updatedNodeStates
      );
    });
  },
};

const TFOpLambdaDescriptor: EntityDescriptorWithoutType<'TFOpLambda'> = {
  name: 'TFOpLambda',
  detailsTitle: 'TFOpLambda DETAILS',
  getDisplayName: ({ data }) => {
    const functionName: string = data?.function;
    const name = functionName
      ? functionName.substring(functionName.lastIndexOf('.') + 1)
      : '';

    return name ? `${name} (TFOpLambda)` : 'TFOpLambda';
  },
  colorTheme: 'layer',
  overrideDetails: true,
  CustomDetails: TFOpLambdaJsonPreviewDetails,
};

const SlicingOpLambdaDescriptor: EntityDescriptorWithoutType<'SlicingOpLambda'> = {
  name: 'SlicingOpLambda',
  detailsTitle: 'TFOpLambda DETAILS',
  colorTheme: 'layer',
  overrideDetails: true,
  CustomDetails: TFOpLambdaJsonPreviewDetails,
};

export const NODE_DESCRIPTORS: {
  [N in NodeDescriptorName]: EntityDescriptor<'node', N>;
} = {
  [DatasetDescriptor.name]: { ...DatasetDescriptor, type: 'node' },
  [InputDescriptor.name]: { ...InputDescriptor, type: 'node' },
  [GroundTruthDescriptor.name]: { ...GroundTruthDescriptor, type: 'node' },
  [DatasetOutputDescriptor.name]: { ...DatasetOutputDescriptor, type: 'node' },
  [VisualizerDescriptor.name]: { ...VisualizerDescriptor, type: 'node' },
  [CustomMetricDescriptor.name]: { ...CustomMetricDescriptor, type: 'node' },
  [CustomLossDescriptor.name]: { ...CustomLossDescriptor, type: 'node' },
  [CustomLayerDescriptor.name]: { ...CustomLayerDescriptor, type: 'node' },
  [TFOpLambdaDescriptor.name]: { ...TFOpLambdaDescriptor, type: 'node' },
  [SlicingOpLambdaDescriptor.name]: {
    ...SlicingOpLambdaDescriptor,
    type: 'node',
  },
};
