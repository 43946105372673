import {
  DatasetVersionInfo,
  JobSubType,
  SessionRunData,
} from '@tensorleap/api-client';

function extractSessionRunDisplayNames(sessionRun: SessionRunData): string[] {
  return sessionRun.jobs
    .filter(
      (job) =>
        job?.datasetVersionInfo !== undefined &&
        job.subType !== undefined &&
        isCreatingSessionRunJob(job.subType)
    )
    .map((job) => extractDatasetVersionDisplayName(job.datasetVersionInfo!));
}

export function extractDatasetVersionDisplayName(
  datasetVersionInfo: DatasetVersionInfo
): string {
  return `${datasetVersionInfo!.datasetName} @${
    datasetVersionInfo.datasetBranchName
  } #${datasetVersionInfo!.datasetVersionDisplayIndex}`;
}

function datasetVersionDisplayNamesToTooltipTitle(
  datasetVersionDisplayNames: string[]
): string {
  return datasetVersionDisplayNames.length === 0
    ? ''
    : datasetVersionDisplayNames.length === 1
    ? `Used ${datasetVersionDisplayNames[0]} code integration`
    : `Used: ${datasetVersionDisplayNames.join(', ')} code integrations`;
}

function sessionRunToDatasetVersionsInfo(sessionRun: SessionRunData) {
  const uniqueDatasetVersionsNames = Array.from(
    new Set(extractSessionRunDisplayNames(sessionRun))
  );
  return datasetVersionDisplayNamesToTooltipTitle(uniqueDatasetVersionsNames);
}

function sessionRunToCreatedAtInfo(sessionRun: SessionRunData) {
  return `Created on ${new Date(sessionRun.createdAt).toLocaleString()}`;
}

function isCreatingSessionRunJob(type: JobSubType) {
  return [
    JobSubType.Evaluate,
    JobSubType.TrainFromScratch,
    JobSubType.ContinueTraining,
    JobSubType.TrainFromInitialWeights,
  ].includes(type);
}

export function sessionRunTooltip(
  sessionRunData: SessionRunData,
  epoch?: number
) {
  return (
    <div className="flex flex-col">
      <span>{sessionRunData.name}</span>
      {sessionRunData.description && (
        <span className="whitespace-pre-wrap">
          {sessionRunData.description}
        </span>
      )}
      {epoch !== undefined && <span>{`Epoch: ${epoch}`}</span>}
      <span>{sessionRunToDatasetVersionsInfo(sessionRunData)}</span>
      <span>{sessionRunToCreatedAtInfo(sessionRunData)}</span>
    </div>
  );
}
