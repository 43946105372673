import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FilterOperatorType,
  HttpMethods,
  SampleIdentity,
} from '@tensorleap/api-client';
import { useScatterData } from '../ScatterDataContext';
import { useCurrentProject } from '../../core/CurrentProjectContext';
import {
  VisPayloadElements,
  VisPayloadType,
} from '../dashlet/VisualizationDisplay/visDataHelpers';
import { useModelFilter } from '../../ui/molecules/useModelFilter';
import {
  MultiSelectType,
  SampleVisualizations,
  useSampleListState,
} from '../dashlet/SampleAnalysis/useSampleListState';
import { VisDisplay } from '../dashlet/VisualizationDisplay/VisDisplay';
import {
  SAMPLES_LOAD_BULK_SIZE,
  useFullVisualizationsFromScatter,
} from '../../core/data-fetching/fullVisualizations';
import { useEnvironmentInfo } from '../../core/EnvironmentInfoContext';
import { MousePosition } from '../../core/useSelectionGroup';
import { calcMetadataObject } from '../useScatterMapData';
import { useToggle } from '../../core/useToggle';
import { groupBy, mapValues } from 'lodash';
import { calcHash } from '../../core/calc-hash';
import api, {
  addBasePathToURL,
  extractStoragePath,
} from '../../core/api-client';
import { isFileExists, uploadJsonFile } from '../../core/file-helper';
import { ClusterVisualizationFilter } from '../../core/types/filters';
import { useMapProjectStorageUrl } from '../../core/useProjectStorage';
import { useDashletScatterContext } from '../dashlet/PopulationExploration/DashletScatterContext';
import { ClusterData } from '@tensorleap/engine-contract';

const sampleToUnique = (sample: SampleIdentity) =>
  `${sample.index}_${sample.state}`;

export const sampleIdentityToString = (sample: SampleIdentity) =>
  `${sample.state}_${sample.index}`;

export interface RightPanelProps {
  mousePosition: MousePosition;
}

export function RightPanel({ mousePosition }: RightPanelProps): JSX.Element {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const {
    scatterData,
    selection: { selected, setSelected },
    scatterSampleVisualizationsPrefix,
    samplesIdsWithAssets,
    sessionRunId,
  } = useScatterData();

  const { selected: _selectedSessionRuns } = useModelFilter();
  const selectedSessionRuns = useMemo(() => {
    const sr = _selectedSessionRuns.find((sr) => sr.id === sessionRunId);
    return sr ? [sr] : [];
  }, [_selectedSessionRuns, sessionRunId]);

  const [selectedSampleIdentities, setSelectedSampleIdentities] = useState<
    SampleIdentity[]
  >([]);

  const sortedSelectedSampleIdentities = useMemo(() => {
    const selectedSamplesWithAssets = selectedSampleIdentities.filter(
      (sample) => samplesIdsWithAssets.has(sampleIdentityToString(sample))
    );

    const selectedSamplesWithoutAssets = selectedSampleIdentities.filter(
      (sample) => !samplesIdsWithAssets.has(sampleIdentityToString(sample))
    );
    return [...selectedSamplesWithAssets, ...selectedSamplesWithoutAssets];
  }, [selectedSampleIdentities, samplesIdsWithAssets]);

  const [toggleMultiSampleSelection, setToggleMultiSampleSelection] = useToggle(
    true
  );

  const [totalSamplesToLoad, setTotalSamplesToLoad] = useState<number>(
    SAMPLES_LOAD_BULK_SIZE
  );

  const [isLoadingSamplesPayloads, setIsLoadingSamplesPayloads] = useState(
    true
  );
  const handleDoneLoadSamples = useCallback(() => {
    setIsLoadingSamplesPayloads(false);
  }, []);

  const handleLoadMoreSamples = useCallback(() => {
    setIsLoadingSamplesPayloads(true);
    setTotalSamplesToLoad((prev) => prev + SAMPLES_LOAD_BULK_SIZE);
  }, []);

  const allowLoadMoreSamples =
    totalSamplesToLoad < selectedSampleIdentities.length;

  const [
    selectedPayloadType,
    setSelectedPayloadType,
  ] = useState<VisPayloadType>(VisPayloadType.Metadata);

  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();

  const selectedSamples = useMemo(() => {
    const samples = scatterData.samples.reduce((acc, sample, index) => {
      if (!sample || !selected?.has(index)) return acc;
      acc.push({
        id: sample,
        slimVisualizationsPerSessionId: {},
      } as SampleVisualizations);
      return acc;
    }, [] as SampleVisualizations[]);

    const samplesWithAssets = samples.filter((sample) =>
      samplesIdsWithAssets.has(sampleIdentityToString(sample.id))
    );
    const samplesWithoutAssets = samples.filter(
      (sample) => !samplesIdsWithAssets.has(sampleIdentityToString(sample.id))
    );
    const sortedSamples = [...samplesWithAssets, ...samplesWithoutAssets];

    return sortedSamples;
  }, [samplesIdsWithAssets, scatterData.samples, selected]);

  const sampleListState = useSampleListState({
    projectId,
    selectedSessionRuns,
    activeSampleIds: sortedSelectedSampleIdentities,
    onActiveSamplesChange: useCallback((newActiveSampleIds) => {
      setSelectedSampleIdentities(newActiveSampleIds);
    }, []),
    samplesOverrides: selectedSamples,
  });

  const {
    visualizations: visualizationsItems,
  } = useFullVisualizationsFromScatter({
    selectedSampleIdentities: sortedSelectedSampleIdentities,
    samplesIdsWithAssets,
    scatterSampleVisualizationsPrefix,
    clientStoragePrefixUrl,
    sessionRunId,
    totalSamplesToLoad,
    handleDoneLoadSamples,
  });

  const metadataObjects = useMemo(() => {
    const metadataEntries = Object.entries(scatterData.metadata);

    const sampleUniqueStringToIndex = new Map<string, number>(
      scatterData.samples.map((sample, index) => [
        sampleToUnique(sample),
        index,
      ])
    );

    return sortedSelectedSampleIdentities.map((sample) => ({
      data: calcMetadataObject(
        metadataEntries,
        sampleUniqueStringToIndex.get(sampleToUnique(sample))
      ),
      sampleIdentity: sample,
    }));
  }, [scatterData, sortedSelectedSampleIdentities]);

  const visPayloadElements: VisPayloadElements = useMemo(() => {
    const defaultVisPayloadElements: VisPayloadElements = {
      [VisPayloadType.Metadata]: { [sessionRunId]: metadataObjects },
      [VisPayloadType.Analysis]: {},
      [VisPayloadType.Visualization]: {},
    };

    Object.entries(visualizationsItems).forEach(([_, items]) => {
      items.forEach((item) => {
        const groupName = item.connection_name || item.visualizer_name;

        if (
          !defaultVisPayloadElements[VisPayloadType.Visualization][groupName]
        ) {
          defaultVisPayloadElements[VisPayloadType.Visualization][
            groupName
          ] = {};
        }
        if (
          !defaultVisPayloadElements[VisPayloadType.Visualization][groupName][
            sessionRunId
          ]
        ) {
          defaultVisPayloadElements[VisPayloadType.Visualization][groupName][
            sessionRunId
          ] = [];
        }

        defaultVisPayloadElements[VisPayloadType.Visualization][groupName][
          sessionRunId
        ].push({ data: item, sampleIdentity: item.sampleIdentity });
      });
    });

    return defaultVisPayloadElements;
  }, [metadataObjects, sessionRunId, visualizationsItems]);

  useEffect(() => {
    if (toggleMultiSampleSelection) {
      setSelectedSampleIdentities(selectedSamples.map((sample) => sample.id));
    }
  }, [toggleMultiSampleSelection, selectedSamples]);

  useEffect(() => {
    if (
      !toggleMultiSampleSelection &&
      sortedSelectedSampleIdentities.length === 0 &&
      selectedSamples.length > 0
    ) {
      setSelectedSampleIdentities([selectedSamples[0].id]);
    }
  }, [
    toggleMultiSampleSelection,
    selectedSamples,
    sortedSelectedSampleIdentities.length,
  ]);

  const onSampleSelect = useCallback(
    (sample: SampleIdentity, multiSelectType: MultiSelectType) => {
      setSelectedSampleIdentities((prev) => {
        let newSelection: SampleIdentity[];
        if (multiSelectType === MultiSelectType.Single) {
          newSelection = [sample];
        } else {
          const index = prev.findIndex(
            (s) => s.index === sample.index && s.state === sample.state
          );
          if (index === -1) {
            newSelection = [...prev, sample];
          } else {
            newSelection = prev.filter((_, i) => i !== index);
          }
        }
        if (
          newSelection.length !== selectedSamples.length &&
          toggleMultiSampleSelection
        ) {
          setToggleMultiSampleSelection();
        }
        return newSelection;
      });
    },
    [
      selectedSamples.length,
      setToggleMultiSampleSelection,
      toggleMultiSampleSelection,
    ]
  );

  const deselectSample = useCallback(
    (sample: SampleIdentity) => {
      if (toggleMultiSampleSelection) {
        setToggleMultiSampleSelection();
      }
      if (selectedSampleIdentities.includes(sample)) {
        setSelectedSampleIdentities((prev) => prev.filter((s) => s !== sample));
      }
    },
    [
      selectedSampleIdentities,
      setToggleMultiSampleSelection,
      toggleMultiSampleSelection,
    ]
  );

  const removeSample = useCallback(
    (sampleToRemove: SampleIdentity) => {
      setSelected((prevSelected) => {
        const newSelected = new Set(prevSelected);
        const indexToRemove = scatterData.samples.findIndex(
          (sample) =>
            sample.index === sampleToRemove.index &&
            sample.state === sampleToRemove.state
        );
        if (indexToRemove !== -1) {
          newSelected.delete(indexToRemove);
        }
        return newSelected;
      });

      setSelectedSampleIdentities((prevSelectedSamples) =>
        prevSelectedSamples.filter(
          (sample) =>
            sample.index !== sampleToRemove.index ||
            sample.state !== sampleToRemove.state
        )
      );
    },
    [scatterData.samples, setSelected]
  );

  const {
    filters: { updateDashletFilters, dashletFilters },
  } = useDashletScatterContext();

  const { mapUrl } = useMapProjectStorageUrl();

  const filterSelected = useCallback(async () => {
    const hash = calcHash(selectedSampleIdentities);
    const samplesIndex: Record<string, number[]> = mapValues(
      groupBy(selectedSampleIdentities, ({ state }) => state),
      (samples) => samples.map((sample) => sample.index)
    );
    const clusterData: ClusterData = {
      is_auto_generated: true,
      cluster_name: hash,
      cluster_value: 'states',
      samples_index: samplesIndex,
      samples_metadata: (null as unknown) as undefined,
    };
    const projectPath = `clusters/${hash}.json`;
    const filePath = mapUrl(projectPath);
    const isExists = await isFileExists(filePath);
    if (!isExists) {
      const blobPath = extractStoragePath(filePath);
      const { url } = await api.getSignedUrl({
        expired: 24 * 60 * 60,
        method: HttpMethods.Put,
        fileName: blobPath,
      });
      const urlWithBasePath = addBasePathToURL(url);
      await uploadJsonFile(urlWithBasePath, clusterData);
    }
    const filter: ClusterVisualizationFilter = {
      field: FilterOperatorType.Cluster,
      operator: FilterOperatorType.Cluster,
      value: {
        url: projectPath,
        state: 'ready',
      },
    };
    updateDashletFilters([...dashletFilters, filter]);
  }, [mapUrl, selectedSampleIdentities, updateDashletFilters, dashletFilters]);

  return (
    <div className="absolute flex flex-1 inset-0 overflow-hidden">
      <div className="flex-1 h-full overflow-hidden">
        <div className="h-full overflow-x-auto overflow-y-hidden">
          <div className="h-full w-max-full">
            <div className="flex flex-1 w-full h-full">
              <VisDisplay
                selectedSessionRuns={selectedSessionRuns}
                sampleListState={{
                  ...sampleListState,
                  onSampleSelect,
                }}
                filterSelectedSamples={filterSelected}
                visPayloadElements={visPayloadElements}
                containerType="populationExploration"
                mousePosition={mousePosition}
                toggleMultiSampleSelection={toggleMultiSampleSelection}
                setToggleMultiSampleSelection={setToggleMultiSampleSelection}
                selectedPayloadType={selectedPayloadType}
                setSelectedPayloadType={setSelectedPayloadType}
                removeSample={removeSample}
                deselectSample={deselectSample}
                showAsGallery={true}
                selectedSampleIdentities={sortedSelectedSampleIdentities}
                loadMoreSamplesState={{
                  isLoading: isLoadingSamplesPayloads,
                  allowLoadMore: allowLoadMoreSamples,
                  handleLoadMoreSamples,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
