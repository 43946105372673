const LOADING_STATUS = {
  ready: 0,
  error: 1,
  updating: 2,
  refreshing: 3,
  loading: 4,
} as const;

export type LoadingStatus = keyof typeof LOADING_STATUS;

export function calcLoadingStatus(data: LoadingStatus[]): LoadingStatus {
  return data.reduce<LoadingStatus>((acc, loadingStatus) => {
    return LOADING_STATUS[loadingStatus] > LOADING_STATUS[acc]
      ? loadingStatus
      : acc;
  }, 'ready');
}

export function isAnyKindOfLoading(loadingStatus: LoadingStatus): boolean {
  return LOADING_STATUS[loadingStatus] >= LOADING_STATUS['updating'];
}
