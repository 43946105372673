import { useEffect, useMemo } from 'react';
import { max } from 'lodash';
import { useVersionControl } from '../../../core/VersionControlContext';
import {
  JobStatus,
  JobType,
  PopulationExplorationArtifacts,
  PopulationExplorationDisplayParams,
  ScatterViz,
  ScatterVizDataState,
  VisualizationResponse,
} from '@tensorleap/api-client';

import { mapToEsFilters } from '../../../model-tests/modelTestHelpers';
import { VisualizationFilter } from '../../../core/types/filters';
import { useMergedObject } from '../../../core/useMergedObject';
import { useInsightsContext } from '../../../insights/InsightsContext';
import {
  useTriggerAndCheckPopulationExplorationStatus,
  DataStatus,
  PeRequestState,
  PopulationExplorationStatus,
  isEndStatus,
} from './useTriggerAndCheckPopulationExplorationStatus';
import { useFetchJson } from '../../../core/data-fetching/fetch-json';
import { LoadingStatus } from '../../../core/data-fetching/loading-status';

const REFRESH_INTERVAL_MS = 60000; // 1 minutes
const MAX_IS_LOADING_TIMEOUT_MS = 900000; // 15 minutes

export type UsePopulationExploration = {
  epochs: number[];
  selectedEpoch: number;
  selectEpoch: (epoch: number) => void;
} & UseFetchVisData;

type UsePopulationExplorationProps = {
  projectId: string;
  sessionRunId: string;
  dashletId: string;
  filters: VisualizationFilter[];
  projectionMetric?: string;
  displayParams: PopulationExplorationDisplayParams;
  epoch: number;
};

export function usePopulationExploration({
  projectId,
  sessionRunId,
  dashletId,
  filters,
  projectionMetric,
  displayParams,
  epoch,
}: UsePopulationExplorationProps) {
  const visData = useFetchVisData({
    projectId,
    sessionRunId,
    epoch,
    dashletId,
    filters,
    projectionMetric,
    displayParams,
  });

  return {
    ...visData,
  };
}

type UseFetchVisData = {
  fullVisualization?: VisualizationResponse;
  loadingStatus: LoadingStatus;
  lastReadyDigest?: string;
  retry: () => void;
};

interface useFetchVisDataParams {
  projectId: string;
  sessionRunId: string;
  epoch: number;
  dashletId: string;
  filters: VisualizationFilter[];
  projectionMetric?: string;
  displayParams: PopulationExplorationDisplayParams;
}

function useFetchVisData({
  projectId,
  sessionRunId,
  epoch,
  dashletId,
  filters,
  projectionMetric,
  displayParams,
}: useFetchVisDataParams): UseFetchVisData {
  const { getSessionRunEpochs, selectedSessionRunMap } = useVersionControl();

  const populationParams = useMemo(
    () => ({
      sessionRunId,
      fromEpoch: epoch,
      batchSize: 1,
      projectionMetric,
      displayParams,
      filters: mapToEsFilters(filters),
    }),
    [sessionRunId, epoch, projectionMetric, displayParams, filters]
  );

  const dataStatus = useMemo(() => {
    const epochs = getSessionRunEpochs(sessionRunId);
    if (epochs.length === 0) return DataStatus.NoData;
    if (epoch < (max(epochs) || 0)) return DataStatus.Ready;
    // when we have epoch it means the job already started
    const jobs = selectedSessionRunMap.get(sessionRunId)?.jobs;
    if (!jobs?.length) return DataStatus.Ready;

    const isRunning = jobs.some(
      ({ status, type }) =>
        status === JobStatus.Started && type === JobType.Training
    );
    return isRunning ? DataStatus.Updating : DataStatus.Ready;
  }, [selectedSessionRunMap, sessionRunId, epoch, getSessionRunEpochs]);

  const { registerInsights, unregisterInsights } = useInsightsContext();

  const { status, retry } = useTriggerAndCheckPopulationExplorationStatus({
    dataStatus,
    populationParams,
    projectId,
  });

  const {
    insightState,
    scatterState,
    scatterLoadingStatus,
    insightLoadingStatus,
  } = useMemo(() => {
    const lastState = status.inProgress ?? status.ready;

    if (lastState?.jobResult?.status === JobStatus.Failed) {
      return {
        insightState: undefined,
        scatterState: undefined,
        scatterLoadingStatus: 'error' as LoadingStatus,
        insightLoadingStatus: 'error' as LoadingStatus,
      };
    }

    const isLastRequestEnd = isEndStatus(
      lastState?.jobResult?.status as JobStatus
    );
    if (isLastRequestEnd) {
      return {
        insightState: lastState,
        scatterState: lastState,
        scatterLoadingStatus: 'ready' as LoadingStatus,
        insightLoadingStatus: 'ready' as LoadingStatus,
      };
    }

    const insightState =
      getStateWithAvailableArtifacts(status, 'insights') ?? lastState;
    const isInsightNotLast = lastState?.digest !== insightState?.digest;
    const isInsightStateNotSameQuery =
      lastState?.baseDigest !== insightState?.baseDigest;

    const scatterState =
      getStateWithAvailableArtifacts(status, 'scatter', 'scatterClusters') ??
      getStateWithAvailableArtifacts(status, 'scatter') ??
      lastState;
    const isScatterNotLast = lastState?.digest !== scatterState?.digest;
    const isScatterStateNotSameQuery =
      lastState?.baseDigest !== scatterState?.baseDigest;

    const scatterLoadingStatus: LoadingStatus = !artifactsExists(
      scatterState,
      'scatter'
    )
      ? 'loading'
      : !artifactsExists(lastState, 'scatterClusters') || isScatterNotLast
      ? isScatterStateNotSameQuery
        ? 'refreshing'
        : 'updating'
      : 'ready';

    const insightLoadingStatus: LoadingStatus = !artifactsExists(
      insightState,
      'insights'
    )
      ? 'loading'
      : isInsightNotLast
      ? isInsightStateNotSameQuery
        ? 'refreshing'
        : 'updating'
      : 'ready';

    return {
      insightState,
      scatterState,
      scatterLoadingStatus,
      insightLoadingStatus,
    } as const;
  }, [status]);

  const insightBlobUrl = insightState?.jobResult?.readyArtifacts.insights;
  const csvBlobUrl = scatterState?.jobResult?.readyArtifacts.analysis;
  const insightBaseDigest =
    insightState?.baseDigest ?? scatterState?.baseDigest;

  useEffect(() => {
    if (!insightBaseDigest) return;
    const registerKey = {
      sessionRunId,
      epoch,
      digest: insightBaseDigest,
      dashletId,
    };
    registerInsights(
      registerKey,
      insightLoadingStatus,
      insightBlobUrl,
      csvBlobUrl
    );
    return () => unregisterInsights(registerKey);
  }, [
    insightBlobUrl,
    insightBaseDigest,
    insightLoadingStatus,
    csvBlobUrl,
    dashletId,
    sessionRunId,
    epoch,
    registerInsights,
    unregisterInsights,
  ]);

  const fullVisualization = useLoadAndCalcScatter(
    scatterState?.jobResult?.readyArtifacts.scatter,
    scatterState?.jobResult?.readyArtifacts.scatterClusters
  );

  return useMergedObject({
    fullVisualization,
    loadingStatus: scatterLoadingStatus,
    lastReadyDigest: scatterState?.baseDigest,
    retry,
  });
}

function useLoadAndCalcScatter(
  scatterBlobUrl?: string,
  scatterClusterBlobUrl?: string
) {
  const { data: scatterVisualization } = useFetchJson<VisualizationResponse>({
    url: scatterBlobUrl,
    refreshIntervalAfterSuccessMS: REFRESH_INTERVAL_MS,
    maxIsLoadingTimeoutMs: MAX_IS_LOADING_TIMEOUT_MS,
  });

  const {
    data: scatterClusterVisualization,
  } = useFetchJson<VisualizationResponse>({
    url: scatterClusterBlobUrl,
    refreshIntervalAfterSuccessMS: REFRESH_INTERVAL_MS,
    maxIsLoadingTimeoutMs: MAX_IS_LOADING_TIMEOUT_MS,
  });

  /**
   * Combines scatter and scatter_cluster data to optimize load times.
   * Initial scatter.json lacks cluster data for quicker rendering, while scatter_cluster adds additional fields later.
   * This method maintains visual consistency during updates.
   * Metadata starts with scatter.json fields, expanded by scatter_cluster. Info and guid, used in filters, are based on scatter_cluster.
   */
  const fullVisualization = useMemo((): VisualizationResponse | undefined => {
    if (!scatterVisualization) {
      return undefined;
    }
    const clusterScatterData = (scatterClusterVisualization?.data.payload[0] as
      | ScatterViz
      | undefined)?.scatter_data;

    const scatterData: ScatterVizDataState = {
      ...clusterScatterData,
      ...(scatterVisualization?.data.payload[0] as ScatterViz).scatter_data,
      metadata: ((scatterClusterVisualization || scatterVisualization)?.data
        .payload[0] as ScatterViz).scatter_data.metadata,
    };
    const payload: ScatterViz = {
      ...scatterVisualization?.data.payload[0],
      guid: (scatterClusterVisualization || scatterVisualization).data
        .payload[0].guid,
      scatter_data: scatterData,
    };
    const info = (scatterClusterVisualization || scatterVisualization).info;
    const visualization: VisualizationResponse = {
      ...scatterVisualization,
      info,
      data: { ...scatterVisualization?.data, payload: [payload] },
    };
    return visualization;
  }, [scatterVisualization, scatterClusterVisualization]);

  return fullVisualization;
}

function getStateWithAvailableArtifacts(
  status: PopulationExplorationStatus,
  ...artifactTypes: (keyof PopulationExplorationArtifacts)[]
): PeRequestState | undefined {
  if (artifactsExists(status.inProgress, ...artifactTypes)) {
    return status.inProgress;
  }
  return artifactsExists(status.ready, ...artifactTypes)
    ? status.ready
    : undefined;
}

function artifactsExists(
  requestState: PeRequestState | undefined,
  ...artifactTypes: (keyof PopulationExplorationArtifacts)[]
): boolean {
  return artifactTypes.every(
    (type) => !!requestState?.jobResult?.readyArtifacts[type]
  );
}
