import { PropsWithChildren, ReactNode } from 'react';
import { Title } from '../../../ui/atoms/Title';
import clsx from 'clsx';
import { BORDER_COLOR_TEMPLATES } from '../../../ui/molecules/colorTemplates';
import { LoadingDot } from '../../../ui/LoadingDot';
import { LinearProgress, Tooltip } from '@material-ui/core';
import { Truncate } from '../../../ui/atoms/Truncate';

// these containers keep the style behavior of session run previews

type PreviewsContainerProps = PropsWithChildren<{
  flex?: boolean;
}>;

export function PreviewsContainer({
  children,
  flex = false,
}: PreviewsContainerProps) {
  return (
    <>
      <div className={clsx('flex overflow-x-auto', flex && 'flex-1')}>
        {children}
      </div>
      {!flex && <span className="flex-1" />}
    </>
  );
}

type PreviewContainerProps = PropsWithChildren<{
  sessionRun: { name: string; modelUniqueKey: number; id: string };
  className?: string;
  flex?: boolean;
  header?: ReactNode;
  loadingType?: 'updating' | 'refreshing';
}>;

export function PreviewContainer({
  sessionRun,
  children,
  header,
  flex,
  className,
  loadingType,
}: PreviewContainerProps): JSX.Element {
  return (
    <div
      className={clsx(
        flex && 'flex-1',
        'flex flex-col h-full overflow-hidden',
        className
      )}
    >
      <div className="flex flex-row px-1 gap-1 justify-between items-center h-9 relative w-full overflow-hidden max-w-full">
        {sessionRun && (
          <div className="flex flex-row-reverse items-start gap-2 w-full justify-between">
            <div className="max-w-full">{header}</div>
            {loadingType === 'updating' && (
              <div className="pt-1 flex-1">
                <LoadingDot />
              </div>
            )}
            <div className="max-w-full overflow-hidden">
              <Title
                ultraSmall
                className="overflow-ellipsis max-w-full"
                bottomBorderClassName={clsx(
                  BORDER_COLOR_TEMPLATES[sessionRun.modelUniqueKey]
                )}
              >
                <Tooltip title={sessionRun.name}>
                  <div>
                    <Truncate value={sessionRun.name} />
                  </div>
                </Tooltip>
              </Title>
            </div>
          </div>
        )}
        {loadingType === 'refreshing' && (
          <LinearProgress className="absolute bottom-0 inset-x-0" />
        )}
      </div>
      <div className="bg-gray-900 flex-1 flex flex-row overflow-auto h-full">
        {children}
      </div>
    </div>
  );
}
