import { DrawerTabsEnum } from '../dashboard/DashboardDrawer/DashboardDrawer';
import { ProjectTabs } from '../ui/ProjectCardTabs';
import { SELECTED_DASHBOARD_KEY, URLS_ENUM } from '../url/url-builder';

import clickNetworkTabGif from './assets/clickNetworkTab.gif';
import clickNodeGif from './assets/clickNode.gif';
import openCommitGif from './assets/openCommit.gif';
import clickAddNewDashletGif from './assets/clickAddNewDashlet.gif';
import addAnalysisDashletGif from './assets/addAnalysisDashlet.gif';
import addPopulationExplorationDashletGif from './assets/addPopulationExplorationDashlet.gif';
import clickDashboardTabGif from './assets/clickDashboardTab.gif';
import popExpChoseSampleGif from './assets/popExpChoseSample.gif';
import clickCodeIntegrationGif from './assets/clickCodeIntegration.gif';
import popExpPreviewOnHoverGif from './assets/popExpPreviewOnHover.gif';
import popExpColorByGif from './assets/popExpColorBy.gif';
import popExpSizeByGif from './assets/popExpDotSize.gif';

export const TOUR_POPOVER_WIDTH = 160;
export const TOUR_POPOVER_HEIGHT = 160;

export enum ToursTypesEnum {
  IMPORT_PROJECT = 'IMPORT_PROJECT',
  EXPLORE_PROJECT = 'EXPLORE_PROJECT',
}

export interface Step {
  selector?: string;
  scrollToSelectorId?: string;
  gifSrc?: string;
  textContent: string;
  skipIf?: () => boolean;
  onNext?: (element: HTMLElement) => void;
  nextButtonText?: string;
  hideNextButton?: boolean;
  allowSkip?: boolean;
  showError?: {
    when: () => boolean;
    message: string;
  };
}

export interface TourSequence {
  description: string;
  steps: Step[];
}

export interface TourCategory {
  name: string;
  icon: JSX.Element;
}
export interface TourData {
  description: string;
  pages: URLS_ENUM[];
  timeMinutes?: number;
  category?: TourCategory;
  sequences: TourSequence[];
}

// selectorsIds

export enum TOUR_SELECTORS_ENUM {
  HUB_GALLERY_ID = 'hub-gallery',
  IMPORT_PROJECT_DIALOG_ID = 'import-project-dialog',
  RUNS_AND_PROCESSES_TABLE_ID = 'run-and-processes-table-id',
  RECENT_PROJECTS_TABLE_ID = 'recent-projects-table-id',
  NETWORK_TAB_ID = 'network-tab',
  DASHBOARD_TAB_ID = 'dashboard-tab',
  VERSION_CONTROL_EXPAND_BUTTON_ID = 'version-control-expand-button',
  VERSION_CONTROL_PANE_ID = 'version-control-pane',
  NO_SESSION_RUNS_CHIP_ID = 'no-session-runs-chip',
  NETWORK_NODE_ID = 'network-node',
  NETWORK_EDITOR_PANE_ID = 'network-editor-pane',
  CODE_INTEGRATION_EXPAND_BUTTON_ID = 'code-integration-panel',
  ADD_NEW_DASHBOARD_BUTTON_ID = 'add-new-dashboard-button',
  ADD_NEW_DASHBOARD_DIALOG_ID = 'add-new-dashboard-dialog',
  ADD_NEW_DASHLET_BUTTON_ID = 'add-new-dashlet-button',
  ADD_NEW_DASHLET_MENU_ID = 'add-new-dashlet-menu',
  ADD_POPULATION_EXPLORATION_DASHLET_BUTTON_ID = 'add-population-exploration-dashlet-button',
  ADD_POPULATION_EXPLORATION_DASHLET_CARD_ID = 'add-population-exploration-dashlet-card',
  POPULATION_EXPLORATION_DASHLET_ID = 'population-exploration-dashlet',
  POPULATION_EXPLORATION_PROCESSING_ID = 'population-exploration-processing',
  POPULATION_EXPLORATION_COLOR_BY_ID = 'population-exploration-color-by',
  POPULATION_EXPLORATION_SIZE_BY_ID = 'population-exploration-size-by',
  POPULATION_EXPLORATION_PREVIEW_ON_HOVER_ID = 'population-exploration-preview-on-hover',
  POPULATION_EXPLORATION_CIRCLES_ID = 'population-exploration-circles',
  POPULATION_EXPLORATION_RIGHT_PANEL_ID = 'population-exploration-right-panel',
  POPULATION_EXPLORATION_VISUALIZE_BUTTON_ID = 'population-exploration-visualize-button',
  POPULATION_EXPLORATION_RIGHT_PANEL_VISUALIZATIONS_ID = 'population-exploration-right-panel-visualizations',
  POPULATION_EXPLORATION_OPEN_SAMPLE_ANALYSIS_DRAWER_BUTTON_ID = 'population-exploration-open-sample-analysis-drawer-button',
  POPULATION_EXPLORATION_SAMPLE_ANALYSIS_BUTTON_ID = 'population-exploration-sample-analysis-button',
  POPULATION_EXPLORATION_DRAWER_GO_BACK_BUTTON_ID = 'population-exploration-drawer-go-back-button',
  POPULATION_EXPLORATION_FETCH_SIMILAR_EXPANDER_BUTTON_ID = 'population-exploration-fetch-similar-button',
  POPULATION_EXPLORATION_FETCH_SIMILAR_DRAWER_ID = 'population-exploration-fetch-similar-drawer',
  POPULATION_EXPLORATION_FETCH_SIMILAR_TRIGGER_BUTTON_ID = 'fetch-similar-trigger-button',
  FETCH_SIMILAR_FILTER_ID = 'fetch-similar-filter',
  ADD_SAMPLE_ANALYSIS_DASHLET_BUTTON_ID = 'add-sample-analysis-dashlet-button',
  ADD_SAMPLE_ANALYSIS_DASHLET_CARD_ID = 'add-sample-analysis-dashlet-card',
  SAMPLE_ANALYSIS_DASHLET_ID = 'sample-analysis-dashlet',
  SAMPLE_ANALYSIS_DASHLET_LOADED_CONTENT_ID = 'sample-analysis-dashlet-loaded-content',
  SAMPLE_ANALYSIS_NO_SAMPLE_SELECTED_ID = 'sample-analysis-no-sample-selected',
  SAMPLE_ANALYSIS_VIS_ASSETS_MENU_ID = 'sample-analysis-visualized-assets-menu',
  SAMPLE_ANALYSIS_VIS_ASSET_ITEM_ID = 'sample-analysis-visualized-asset-item',
  SAMPLE_ANALYSIS_VISUALIZED_ITEM_ID = 'sample-analysis-heat-map-vis',
  HEAT_MAP_SLIDERS_ID = 'heat-map-sliders',
  SAMPLE_ANALYSIS_DEPTH_SELECTOR_ID = 'sample-analysis-depth-selector',
  ADD_ANALYTICS_DASHLET_BUTTON_ID = 'add-analytics-dashlet-button',
  ADD_ANALYTICS_DASHLET_CARD_ID = 'add-analytics-dashlet',
  ANALYTICS_DASHLET_ID = 'analytics-dashlet',
  INSIGHTS_PANEL_BUTTON_ID = 'insights-panel-button',
  INSIGHTS_LIST_ID = 'insights-list',
  INSIGHTS_CATAGORIES_TABS_ID = 'insights-categories-tabs',
  INSIGHT_CARD_ID = 'insight-card',
  INSIGHT_HEADER_ID = 'insight-header',
  INSIGHT_FILTER_BUTTON_ID = 'insight-filter-button',
  INSIGHT_DISPLAY_BUTTON_ID = 'insight-display-button',
  PINNED_INSIGHT_ID = 'pinned-insight',
  ISSUES_PANEL_BUTTON_ID = 'issues-panel-button',
  CREATE_NEW_ISSUE_BUTTON_ID = 'create-new-issue-button',
  TESTS_PANEL_BUTTON_ID = 'tests-panel-button',
  TESTS_SUMMARY_ID = 'test-list',
  CREATE_NEW_TEST_BUTTON_ID = 'create-new-test-button',
}

//steps
const openVersionControlPanel: Step = {
  selector: TOUR_SELECTORS_ENUM.VERSION_CONTROL_EXPAND_BUTTON_ID,
  textContent: "Let's open the version control panel",
  onNext: clickElement,
  skipIf: () => isElementExist(TOUR_SELECTORS_ENUM.VERSION_CONTROL_PANE_ID),
};

const addSessionRun: Step = {
  selector: TOUR_SELECTORS_ENUM.VERSION_CONTROL_PANE_ID,
  textContent:
    'Choose a model version and click on a session run to view the results',
  skipIf: () => !isElementExist(TOUR_SELECTORS_ENUM.NO_SESSION_RUNS_CHIP_ID),
  hideNextButton: true,
};

const openVCAndAddSessionRun: Step[] = [
  {
    ...openVersionControlPanel,
    skipIf: () =>
      (openVersionControlPanel.skipIf && openVersionControlPanel.skipIf()) ||
      (addSessionRun.skipIf && addSessionRun.skipIf()) ||
      false,
  },
  addSessionRun,
];

const goToDashboardTab: Step = {
  selector: TOUR_SELECTORS_ENUM.DASHBOARD_TAB_ID,
  textContent:
    "Now, let's analyze the model's results! Navigate to the Dashboard panel",
  onNext: clickElement,
  gifSrc: clickDashboardTabGif,
  skipIf: () => window.location.pathname.includes(ProjectTabs.Dashboard),
};

const goToNetworkTab: Step = {
  selector: TOUR_SELECTORS_ENUM.NETWORK_TAB_ID,
  textContent: 'In this tab we can see the network diagram and its components',
  onNext: clickElement,
  skipIf: () => window.location.pathname.includes(ProjectTabs.Network),
  gifSrc: clickNetworkTabGif,
};

const openAddNewDashboardDialog: Step = {
  selector: TOUR_SELECTORS_ENUM.ADD_NEW_DASHBOARD_BUTTON_ID,
  textContent: 'Add a new Dashboard',
  onNext: clickElement,
  nextButtonText: 'Add',
  skipIf: () =>
    window.location.search.includes(SELECTED_DASHBOARD_KEY) ||
    isElementExist(TOUR_SELECTORS_ENUM.ADD_NEW_DASHBOARD_DIALOG_ID),
};

const createNewDashboard: Step = {
  selector: TOUR_SELECTORS_ENUM.ADD_NEW_DASHBOARD_DIALOG_ID,
  textContent:
    'Fill the necessary fields and click on add to create a new dashboard',
  hideNextButton: true,
  skipIf: () =>
    !isElementExist(TOUR_SELECTORS_ENUM.ADD_NEW_DASHBOARD_BUTTON_ID) &&
    window.location.search.includes(SELECTED_DASHBOARD_KEY),
};

const addNewDashletForPopExp: Step = {
  selector: TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_BUTTON_ID,
  textContent:
    'Now, let’s explore the model’s latent space. Add a new dashlet to the dashboard',
  onNext: clickElement,
  gifSrc: clickAddNewDashletGif,
  skipIf: () =>
    isElementExist(TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_MENU_ID) ||
    isElementExist(TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID),
};

const addNewDashletForSampleAnalysis: Step = {
  ...addNewDashletForPopExp,
  textContent:
    'Now, let’s analyze some specific samples. Add a new dashlet to the dashboard',
  skipIf: () =>
    isElementExist(TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_MENU_ID) ||
    isElementExist(TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID),
};

const addNewDashletForAnalytics: Step = {
  ...addNewDashletForPopExp,
  textContent:
    'Now, let’s analyze the model’s performance. Add a new dashlet to the dashboard',
  skipIf: () =>
    isElementExist(TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_MENU_ID) ||
    isElementExist(TOUR_SELECTORS_ENUM.ANALYTICS_DASHLET_ID),
};

const addPopulationExplorationDashlet: Step = {
  selector: TOUR_SELECTORS_ENUM.ADD_POPULATION_EXPLORATION_DASHLET_CARD_ID,
  textContent: 'Choose population exploration dashlet',
  gifSrc: addPopulationExplorationDashletGif,
  onNext: (_) => {
    const element = document.getElementById(
      TOUR_SELECTORS_ENUM.ADD_POPULATION_EXPLORATION_DASHLET_BUTTON_ID
    );
    if (!element) {
      console.error(
        'Element not found',
        TOUR_SELECTORS_ENUM.ADD_POPULATION_EXPLORATION_DASHLET_BUTTON_ID
      );
      return;
    }
    element.click();
  },
  skipIf: () =>
    isElementExist(TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID),
};

const addSampleAnalysisDashlet: Step = {
  selector: TOUR_SELECTORS_ENUM.ADD_SAMPLE_ANALYSIS_DASHLET_CARD_ID,
  textContent: 'Choose sample analysis dashlet',
  gifSrc: addAnalysisDashletGif,
  onNext: (_) => {
    const element = document.getElementById(
      TOUR_SELECTORS_ENUM.ADD_SAMPLE_ANALYSIS_DASHLET_BUTTON_ID
    );
    if (!element) {
      console.error(
        'Element not found',
        TOUR_SELECTORS_ENUM.ADD_SAMPLE_ANALYSIS_DASHLET_BUTTON_ID
      );
      return;
    }
    element.click();
  },
  skipIf: () => isElementExist(TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID),
};

const addAnalyticsDashlet: Step = {
  selector: TOUR_SELECTORS_ENUM.ADD_ANALYTICS_DASHLET_CARD_ID,
  textContent: 'Choose analytics dashlet',
  gifSrc: addAnalysisDashletGif,
  onNext: (_) => {
    const element = document.getElementById(
      TOUR_SELECTORS_ENUM.ADD_ANALYTICS_DASHLET_BUTTON_ID
    );
    if (!element) {
      console.error(
        'Element not found',
        TOUR_SELECTORS_ENUM.ADD_ANALYTICS_DASHLET_BUTTON_ID
      );
      return;
    }
    element.click();
  },
  skipIf: () => isElementExist(TOUR_SELECTORS_ENUM.ANALYTICS_DASHLET_ID),
};

const popExpIsProcessing: Step = {
  selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
  textContent:
    'The samples are being processed, this may take a few seconds...',
  hideNextButton: true,
  skipIf: () =>
    !isElementExist(TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_PROCESSING_ID),
};

const choosePopExpSample: Step = {
  selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_CIRCLES_ID,
  textContent: 'Let’s choose a sample we wish to investigate',
  gifSrc: popExpChoseSampleGif,
  skipIf: () =>
    isElementExist(TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_RIGHT_PANEL_ID),
  hideNextButton: true,
};

const visualizePopExpSamples: Step = {
  selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_VISUALIZE_BUTTON_ID,
  textContent: 'Click Visualize for create the visualizions',
  skipIf: () =>
    isElementExist(
      TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_RIGHT_PANEL_VISUALIZATIONS_ID
    ),
  onNext: clickElement,
  nextButtonText: 'Visualize',
};

//sequences
const importProjectSequence: TourSequence = {
  description: 'Import a demo project from the gallery',
  steps: [
    {
      selector: TOUR_SELECTORS_ENUM.HUB_GALLERY_ID,
      textContent: 'Choose one of the example projects and click import',
      skipIf: () =>
        !!document.getElementById(TOUR_SELECTORS_ENUM.IMPORT_PROJECT_DIALOG_ID),
      hideNextButton: true,
    },
    {
      selector: TOUR_SELECTORS_ENUM.IMPORT_PROJECT_DIALOG_ID,
      textContent: 'Click import to start the project import',
      skipIf: () =>
        !document.getElementById(TOUR_SELECTORS_ENUM.IMPORT_PROJECT_DIALOG_ID),
      hideNextButton: true,
    },
    {
      selector: TOUR_SELECTORS_ENUM.RUNS_AND_PROCESSES_TABLE_ID,
      textContent: 'Follow the import process using the Run & Processes',
    },
    {
      selector: TOUR_SELECTORS_ENUM.RECENT_PROJECTS_TABLE_ID,
      textContent:
        'Click on the imported project once the import is done and continue to the next tour',
    },
    {
      textContent:
        'This import process can take a while, feel free to grab a coffee while you wait',
    },
  ],
};

const networkSequence: TourSequence = {
  description: 'Network',
  steps: [
    goToNetworkTab,
    {
      ...openVersionControlPanel,
      textContent: "Let's start by opening the version control panel",
    },
    {
      selector: TOUR_SELECTORS_ENUM.VERSION_CONTROL_PANE_ID,
      textContent:
        'Via the version control panel, you can select the commit you wish',
      onNext: clickElement,
      gifSrc: openCommitGif,
    },
    {
      selector: TOUR_SELECTORS_ENUM.NETWORK_NODE_ID,
      textContent: 'Click on any node to view or edit its details',
      gifSrc: clickNodeGif,
      onNext: clickElement,
      skipIf: () => isElementExist(TOUR_SELECTORS_ENUM.NETWORK_EDITOR_PANE_ID),
    },
    {
      selector: TOUR_SELECTORS_ENUM.NETWORK_EDITOR_PANE_ID,
      textContent: 'Here you can view the node details and edit the node',
    },
    {
      selector: TOUR_SELECTORS_ENUM.CODE_INTEGRATION_EXPAND_BUTTON_ID,
      textContent:
        'Click on code integration to view a summary of the integrated dataset, including metrics, metadata, and visualizers',
      onNext: clickElement,
      gifSrc: clickCodeIntegrationGif,
    },
    goToDashboardTab,
  ],
};

const versionControlSequence: TourSequence = {
  description: 'Version Control',
  steps: openVCAndAddSessionRun,
};

const populationExplorationSequence: TourSequence = {
  description: 'Population Exploration',
  steps: [
    goToDashboardTab,
    openAddNewDashboardDialog,
    createNewDashboard,
    ...openVCAndAddSessionRun,
    addNewDashletForPopExp,
    addPopulationExplorationDashlet,
    {
      selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        'The Population Exploration provides a view of the model’s latent space',
    },
    popExpIsProcessing,
    {
      selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_PREVIEW_ON_HOVER_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        'You can choose a visualization method to display when hovering over the samples',
      gifSrc: popExpPreviewOnHoverGif,
    },
    {
      selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_COLOR_BY_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        'You can investigate the latent space by coloring the samples based on a chosen variable',
      gifSrc: popExpColorByGif,
    },
    {
      selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_SIZE_BY_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent: 'and by controlling the dot size',
      gifSrc: popExpSizeByGif,
    },
    choosePopExpSample,
    {
      selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_RIGHT_PANEL_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        'In the sample details, we can see the sample’s metadata and visualizations',
    },
    visualizePopExpSamples,
    {
      selector:
        TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_OPEN_SAMPLE_ANALYSIS_DRAWER_BUTTON_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent: 'We can further analyze the sample’s extracted features',
      onNext: clickElement,
      skipIf: () =>
        isElementExist(
          TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_SAMPLE_ANALYSIS_BUTTON_ID
        ),
    },
    {
      selector:
        TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_SAMPLE_ANALYSIS_BUTTON_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent: 'Click Analyze Sample to start the sample analysis process',
      nextButtonText: 'Analyze Sample',
      allowSkip: true,
      onNext: clickElement,
    },
    {
      selector:
        TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DRAWER_GO_BACK_BUTTON_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent: `Let's go back to the previous view`,
      onNext: clickElement,
      skipIf: () =>
        !isElementExist(
          TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DRAWER_GO_BACK_BUTTON_ID
        ),
    },
  ],
};

const fetchSimilarSequence: TourSequence = {
  description: 'Fetch Similar',
  steps: [
    goToDashboardTab,
    openAddNewDashboardDialog,
    createNewDashboard,
    ...openVCAndAddSessionRun,
    addNewDashletForPopExp,
    addPopulationExplorationDashlet,
    choosePopExpSample,
    {
      selector:
        TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_EXPANDER_BUTTON_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        'Additionally, we can fetch samples that are similarly represented',
      skipIf: () =>
        isElementExist(
          TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_DRAWER_ID
        ),
      onNext: clickElement,
    },
    {
      selector:
        TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_DRAWER_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        'Fill the necessary fields and click on fetch similar to retrieve the samples',
      nextButtonText: 'Fetch Similar',
      allowSkip: true,
      onNext: (_) => {
        const fetchSimilarTriggerButton = document.getElementById(
          TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_TRIGGER_BUTTON_ID
        );
        if (!fetchSimilarTriggerButton) {
          console.error('fetchSimilarTriggerButton not found');
          return;
        }
        fetchSimilarTriggerButton.click();
      },
      skipIf: () =>
        isElementExist(TOUR_SELECTORS_ENUM.FETCH_SIMILAR_FILTER_ID) &&
        !isElementExist(
          TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_DRAWER_ID
        ),
    },
    {
      selector: TOUR_SELECTORS_ENUM.FETCH_SIMILAR_FILTER_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        'Now, a filter is created showing samples most similar to the analyzed sample',
      skipIf: () =>
        !isElementExist(TOUR_SELECTORS_ENUM.FETCH_SIMILAR_FILTER_ID),
    },
  ],
};

const sampleAnalysisSequence: TourSequence = {
  description: 'Sample Analysis',
  steps: [
    goToDashboardTab,
    openAddNewDashboardDialog,
    createNewDashboard,
    ...openVCAndAddSessionRun,
    addNewDashletForSampleAnalysis,
    addSampleAnalysisDashlet,
    {
      selector: TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_BUTTON_ID,
      textContent:
        'Now, let’s learn about the Sample Analysis dashlet. Add it to the dashboard',
      skipIf: () =>
        isElementExist(TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID) ||
        isElementExist(TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_MENU_ID),
    },
    {
      selector: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID,
      textContent:
        "When the sample analysis finishes, you'll receive an update via the notification center and this tour will continue, so stay tuned!",
      skipIf: () =>
        isElementExist(
          TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_LOADED_CONTENT_ID
        ),
      hideNextButton: true,
      showError: {
        when: () =>
          isElementExist(
            TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_NO_SAMPLE_SELECTED_ID
          ),
        message:
          'For continue the tour you should complete the Population Exploration step first, make sure you exectued the Sample Analysis process',
      },
    },
    {
      scrollToSelectorId: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID,
      selector: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID,
      textContent: "Let's explore the analysis results",
    },
    {
      scrollToSelectorId: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID,
      selector: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSETS_MENU_ID,
      textContent: 'Select an analysis item to view the results',
      onNext: (_) => {
        const element = document.getElementById(
          TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSET_ITEM_ID
        );
        if (!element) {
          console.error(
            'Element not found',
            TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSET_ITEM_ID
          );
          return;
        }
        element.click();
      },
      skipIf: () =>
        isElementExist(TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VISUALIZED_ITEM_ID),
      nextButtonText: 'Select for me',
    },
    {
      scrollToSelectorId: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID,
      selector: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VISUALIZED_ITEM_ID,
      textContent:
        "The heatmap visualizes how the features guided the model's prediction",
      skipIf: () =>
        !isElementExist(TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSETS_MENU_ID),
    },
    {
      selector: TOUR_SELECTORS_ENUM.HEAT_MAP_SLIDERS_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID,
      textContent:
        'We can adjust the value range  and control the opacity using the sliders',
      skipIf: () =>
        !isElementExist(TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSETS_MENU_ID),
    },
    {
      selector: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DEPTH_SELECTOR_ID,
      scrollToSelectorId: TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_ID,
      textContent:
        'Additionally, we can adjust the depth of gradient retrieval layers to explore different semantic levels',
      skipIf: () =>
        !isElementExist(TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSETS_MENU_ID),
    },
  ],
};

const analyticsSequence: TourSequence = {
  description: 'Analytics',
  steps: [
    goToDashboardTab,
    openAddNewDashboardDialog,
    createNewDashboard,
    ...openVCAndAddSessionRun,
    addNewDashletForAnalytics,
    addAnalyticsDashlet,
    {
      selector: TOUR_SELECTORS_ENUM.ANALYTICS_DASHLET_ID,
      textContent:
        'We can visualize the model’s performance metrics and the distribution of sample metadata',
    },
  ],
};

const insightsSequence: TourSequence = {
  description: 'Insights',
  steps: [
    goToDashboardTab,
    openAddNewDashboardDialog,
    createNewDashboard,
    ...openVCAndAddSessionRun,
    addNewDashletForPopExp,
    addPopulationExplorationDashlet,
    popExpIsProcessing,
    {
      selector: TOUR_SELECTORS_ENUM.INSIGHTS_PANEL_BUTTON_ID,
      textContent:
        'Use the automatically detected insights to iteratively improve your model. Explore these insights to make informed decisions and enhance performance',
      onNext: clickElement,
      skipIf: () =>
        window.location.pathname.includes(URLS_ENUM.PANEL) &&
        window.location.pathname.includes(DrawerTabsEnum.Insights),
    },
    {
      selector: TOUR_SELECTORS_ENUM.INSIGHTS_CATAGORIES_TABS_ID,
      textContent:
        'The insights are categorized into two types: performance-related issues and data curation-related insights',
    },
    {
      selector: TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID,
      textContent:
        "Let's explore the Population Exploration dashlet and how the insights will be presented in it",
    },
    {
      selector: TOUR_SELECTORS_ENUM.INSIGHT_CARD_ID,
      textContent: "Also, let's explore the first insight",
    },
    {
      scrollToSelectorId: TOUR_SELECTORS_ENUM.INSIGHT_CARD_ID,
      selector: TOUR_SELECTORS_ENUM.INSIGHT_HEADER_ID,
      textContent: 'Preview the cluster in the latent space',
    },
    {
      scrollToSelectorId: TOUR_SELECTORS_ENUM.INSIGHT_CARD_ID,
      selector: TOUR_SELECTORS_ENUM.INSIGHT_DISPLAY_BUTTON_ID,
      onNext: clickElement,
      nextButtonText: 'Display',
      textContent: 'Display the cluster samples to investigate',
    },
    {
      scrollToSelectorId: TOUR_SELECTORS_ENUM.INSIGHT_CARD_ID,
      selector: TOUR_SELECTORS_ENUM.INSIGHT_FILTER_BUTTON_ID,
      textContent:
        'Filter the cluster samples across the dashboard to analyze and identify patterns',
      onNext: clickElement,
      nextButtonText: 'Filter',
    },
  ],
};

const issuesSequence: TourSequence = {
  description: 'Issues',
  steps: [
    goToDashboardTab,
    openAddNewDashboardDialog,
    createNewDashboard,
    ...openVCAndAddSessionRun,
    {
      selector: TOUR_SELECTORS_ENUM.ISSUES_PANEL_BUTTON_ID,
      textContent: 'To collaborate with your team you can use the issues panel',

      onNext: (element) => {
        element.click();
      },
      skipIf: () =>
        window.location.pathname.includes(URLS_ENUM.PANEL) &&
        window.location.pathname.includes(DrawerTabsEnum.Issues),
    },
    {
      selector: TOUR_SELECTORS_ENUM.CREATE_NEW_ISSUE_BUTTON_ID,
      textContent: 'Click here to open a new issue',
    },
  ],
};

const testsSequence: TourSequence = {
  description: 'Tests',
  steps: [
    goToDashboardTab,
    openAddNewDashboardDialog,
    createNewDashboard,
    ...openVCAndAddSessionRun,
    {
      selector: TOUR_SELECTORS_ENUM.TESTS_PANEL_BUTTON_ID,
      textContent: 'We can build test scenarios to monitor model performance',
      onNext: (element) => {
        element.click();
      },
      skipIf: () =>
        window.location.pathname.includes(URLS_ENUM.PANEL) &&
        window.location.pathname.includes(DrawerTabsEnum.Tests),
    },
    {
      selector: TOUR_SELECTORS_ENUM.TESTS_SUMMARY_ID,
      textContent:
        'Here is a summary view of total failed and successful tests',
    },
    {
      selector: TOUR_SELECTORS_ENUM.CREATE_NEW_TEST_BUTTON_ID,
      textContent: 'Click here to create a new test',
    },
  ],
};

export const ToursData: Record<ToursTypesEnum, TourData> = {
  [ToursTypesEnum.IMPORT_PROJECT]: {
    description: 'Import a project from the gallery',
    pages: [URLS_ENUM.WELCOME],
    sequences: [importProjectSequence],
  },
  [ToursTypesEnum.EXPLORE_PROJECT]: {
    description: 'Explore the project',
    pages: [URLS_ENUM.PROJECT],
    sequences: [
      networkSequence,
      versionControlSequence,
      populationExplorationSequence,
      fetchSimilarSequence,
      sampleAnalysisSequence,
      analyticsSequence,
      insightsSequence,
      issuesSequence,
      testsSequence,
    ],
  },
};

function isElementExist(selector: string) {
  return !!document.getElementById(selector);
}

function clickElement(element: HTMLElement) {
  element.click();
}
