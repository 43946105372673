import { MoreUp } from '../../../ui/icons';
import { Tooltip } from '@material-ui/core';
import { UseEpochVisualizationState } from './useEpochVisualization';
import { dateFormatter } from '../../../core/formatters/date-formatting';
import { Button } from '../../../ui/atoms/Button';
import { Select } from '../../../ui/atoms/Select';
import clsx from 'clsx';

type EpochVisualizationSelectorProps = UseEpochVisualizationState;

export function EpochVisualizationSelector({
  selectEpoch,
  epochsOptions,
  selectedEpoch,
  upgradeToLastEpoch,
  needsUpgrade,
}: EpochVisualizationSelectorProps) {
  return (
    <div className={clsx('flex items-center w-fit', needsUpgrade && 'gap-1')}>
      {needsUpgrade && (
        <Tooltip title="Create visualization to latest epoch">
          <Button
            variant="action-icon"
            className="w-2 h-7"
            onClick={upgradeToLastEpoch}
          >
            <MoreUp />
          </Button>
        </Tooltip>
      )}
      <Select
        ultraSmall
        className="z-[4000] min-w-[120px]"
        value={epochsOptions.findIndex((epoch) => epoch === selectedEpoch)}
        options={epochsOptions.map((_, index) => index)}
        optionToLabel={(epochIndex) =>
          `${
            epochsOptions[epochIndex].slimVisualization?.createdAt
              ? dateFormatter.format(
                  epochsOptions[epochIndex].slimVisualization?.createdAt
                )
              : 'No visualization'
          }`
        }
        required
        onChange={(index) =>
          selectEpoch(epochsOptions[index ? parseInt(index) : 0])
        }
      />
    </div>
  );
}
