import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '../../ui/mui';
import { Trash } from '../icons';
import { ActionButton } from '../molecules/ActionButton';
import { Button } from './Button';
import clsx from 'clsx';

export interface ConfirmDialogProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  confirmButtonText?: string;
  confirmButtonIcon?: JSX.Element;
  confirmButtonColor?: 'blue' | 'red' | 'none';
  cancelButtonColor?: 'blue' | 'none';
}

export const ConfirmDialog = React.forwardRef<
  HTMLDivElement,
  ConfirmDialogProps
>(
  (
    {
      title,
      isOpen,
      onClose,
      onConfirm,
      confirmButtonText = 'delete',
      confirmButtonIcon = <Trash />,
      confirmButtonColor = 'red',
      cancelButtonColor = 'none',
    },
    ref
  ) => {
    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth="xl">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div className="flex flex-row mb-2 gap-2 justify-end" ref={ref}>
            <Button
              variant={
                cancelButtonColor === 'blue' ? undefined : 'inverted-gray'
              }
              className={clsx(
                cancelButtonColor === 'blue'
                  ? 'bg-dashboard-500 hover:bg-dashboard-700 hover:text-white active:bg-primary-400 focus:bg-dashboard-400'
                  : ''
              )}
              onClick={onClose}
            >
              Cancel
            </Button>
            <ActionButton
              icon={confirmButtonIcon}
              className={clsx(
                confirmButtonColor === 'red'
                  ? 'bg-error-500 hover:bg-error-700 hover:text-white active:bg-error-400 focus:bg-error-400'
                  : confirmButtonColor === 'blue'
                  ? 'bg-dashboard-500 hover:bg-dashboard-700 hover:text-white active:bg-primary-400 focus:bg-dashboard-400'
                  : ' border-[1px]'
              )}
              onRun={onConfirm}
            >
              {confirmButtonText}
            </ActionButton>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

ConfirmDialog.displayName = 'DeleteContentDialog';
