import { useMemo } from 'react';
import { useNetworkWizardContext } from './NetworkWizardContext';
import { WizardCard } from './cards/WizardCard';
import {
  NetworkWizardErrorSeverity,
  NetworkWizardCategory,
  NetworkWizardData,
} from './types';
import { useWizardActions } from './state-managers/WizardActionsButtonsState';
import { Button } from '../../ui/atoms/Button';
import {
  ApplyMapIcon,
  Check1,
  CloudDlBigIcon,
  PlusMini,
  UpdateMapIcon,
} from '../../ui/icons';
import { Divider } from '../../ui/mui';

import { useMappingUtils } from '../../core/mappingUtils';
import { useCurrentProject } from '../../core/CurrentProjectContext';

import { ValidateAssetsButton } from '../../ui/molecules/ValidateAssetsButton';
import { useFreemium } from '../../trial/useFreemium';

const SEVERITY_ORDER: Record<NetworkWizardErrorSeverity, number> = {
  [NetworkWizardErrorSeverity.ERROR]: 0,
  [NetworkWizardErrorSeverity.WARNING]: 1,
  [NetworkWizardErrorSeverity.INFO]: 2,
};

const CATEGORY_ORDER: Record<NetworkWizardCategory, number> = {
  [NetworkWizardCategory.MODEL]: 0,
  [NetworkWizardCategory.CODE]: 1,
  [NetworkWizardCategory.INPUTS]: 2,
  [NetworkWizardCategory.LOSS]: 3,
  [NetworkWizardCategory.METRICS]: 4,
  [NetworkWizardCategory.VIS]: 5,
};

const SHARED_ACTION_CLASSES =
  'flex h-[2.25rem] w-[17rem] text-xs uppercase justify-center items-center';
const ACTION_BUTTON_ICON_CLASSES = 'h-5 w-5';
const ACTION_BUTTON_TEXT_CLASSES = 'mt-[0.1rem]';

export interface NetworkWizardContentProps {
  selectedCategory?: NetworkWizardCategory;
}

export function NetworkWizardContent({
  selectedCategory,
}: NetworkWizardContentProps): JSX.Element {
  const { allAlerts } = useNetworkWizardContext();
  const orderedAlerts = useMemo(() => {
    const alerts = selectedCategory
      ? allAlerts.filter(({ category }) => category === selectedCategory)
      : allAlerts;
    const ordered = alerts.sort((a, b) => {
      if (a.errorSeverity === b.errorSeverity) {
        return CATEGORY_ORDER[a.category] - CATEGORY_ORDER[b.category];
      }
      return SEVERITY_ORDER[a.errorSeverity] - SEVERITY_ORDER[b.errorSeverity];
    });
    return ordered;
  }, [allAlerts, selectedCategory]);

  return allAlerts.length > 0 ? (
    <OrderedAlertCards orderedAlerts={orderedAlerts} />
  ) : (
    <ActionButtons />
  );
}

function OrderedAlertCards({
  orderedAlerts,
}: {
  orderedAlerts: NetworkWizardData[];
}): JSX.Element {
  return (
    <div className="flex justify-start items-center flex-col gap-3 mb-2 mt-4 max-h-screen-12rem w-[22rem] overflow-y-auto">
      {orderedAlerts.map((alert, index) => (
        <WizardCard {...alert} key={alert.key + index} />
      ))}
    </div>
  );
}

function ActionButtons(): JSX.Element {
  return (
    <div className="flex justify-start items-center flex-col gap-3 mb-2 mt-4 max-h-screen-12rem w-[22rem] overflow-y-auto">
      <WizardValidateButton />
      <WizardMappingButtons />
      <WizardEvalTrainButtons />
    </div>
  );
}

function WizardValidateButton(): JSX.Element {
  const { showValidateAssetButton } = useWizardActions();
  return (
    <>
      {showValidateAssetButton && (
        <ValidateAssetsButton className={SHARED_ACTION_CLASSES} />
      )}
    </>
  );
}

function WizardMappingButtons(): JSX.Element {
  const {
    showDownloadMappingButton,
    showCreateMappingMappingButton: showCreateMappingFileButton,
    showUpdateApplyMappingButtons,
  } = useWizardActions();
  const {
    addOrUpdateNetworkMapping,
    applyDatasetMapping,
    downloadMapping,
  } = useMappingUtils();

  return (
    <>
      {showCreateMappingFileButton && (
        <Button
          variant="inverted-filled-gray"
          className={SHARED_ACTION_CLASSES}
          onClick={addOrUpdateNetworkMapping}
        >
          <PlusMini className={ACTION_BUTTON_ICON_CLASSES} />
          <span className={ACTION_BUTTON_TEXT_CLASSES}>
            Create Mapping File
          </span>
        </Button>
      )}
      {showUpdateApplyMappingButtons && (
        <>
          <Button
            variant="inverted-filled-gray"
            className={SHARED_ACTION_CLASSES}
            onClick={addOrUpdateNetworkMapping}
          >
            <UpdateMapIcon className={ACTION_BUTTON_ICON_CLASSES} />
            <span className={ACTION_BUTTON_TEXT_CLASSES}>Update Mapping</span>
          </Button>
          <Button
            variant="inverted-filled-gray"
            className={SHARED_ACTION_CLASSES}
            onClick={applyDatasetMapping}
          >
            <ApplyMapIcon className={ACTION_BUTTON_ICON_CLASSES} />
            <span className={ACTION_BUTTON_TEXT_CLASSES}>Apply Mapping</span>
          </Button>
        </>
      )}
      {showDownloadMappingButton && (
        <Button
          variant="inverted-filled-gray"
          className={SHARED_ACTION_CLASSES}
          onClick={downloadMapping}
        >
          <CloudDlBigIcon className={ACTION_BUTTON_ICON_CLASSES} />
          <span className={ACTION_BUTTON_TEXT_CLASSES}>
            Download Mapping File
          </span>
        </Button>
      )}
    </>
  );
}

function WizardEvalTrainButtons(): JSX.Element {
  const { showEvalTrainButton } = useWizardActions();
  const { openEvaluateDialog } = useCurrentProject();
  const { upgradeDialog } = useFreemium();

  return (
    <>
      {showEvalTrainButton && (
        <>
          {upgradeDialog}
          <Divider className="flex w-[17rem] bg-gray-800 " />
          <Button
            variant="inverted-filled-gray"
            className={SHARED_ACTION_CLASSES}
            onClick={() => openEvaluateDialog()}
          >
            <Check1 className={ACTION_BUTTON_ICON_CLASSES} />
            <span className={ACTION_BUTTON_TEXT_CLASSES}>Evaluate Model</span>
          </Button>
        </>
      )}
    </>
  );
}
