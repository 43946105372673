import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  FilterOperatorType,
  InsightFilterDisplayData,
  InsightType,
} from '@tensorleap/api-client';
import { ArchiveIcon, FwArrowIcon, Target1 } from '../ui/icons';
import { ActionButton } from '../ui/molecules/ActionButton';
import { CardFooter, Card } from '../ui/molecules/Card';
import { useDashboardContext } from '../dashboard/DashboardContext';
import { ClusterVisualizationFilter } from '../core/types/filters';
import { Tooltip } from '../ui/mui';
import { SelectedSessionRun } from '../ui/molecules/useModelFilter';
import { InsightCardBody } from './InsightCardBody';
import { InsightHeader } from './InsightCardHeader';
import { trimStorageUrlProject } from '../core/useProjectStorage';
import { InsightSubCategoryType } from './utils';
import { useInsightsContext } from './InsightsContext';

export type InsightCardProps = {
  title?: string;
  clusterUrl?: string;
  asPopup?: boolean;
  onClose?: () => void;
  isWaitingForUpdate?: boolean;
  body?: JSX.Element;
  insightData?: InsightType;
  insightSubType?: InsightSubCategoryType;
  selectedSessionRun?: SelectedSessionRun;
  tourId?: string;
  headerTourId?: string;
  displayButtonTourId?: string;
  filterButtonTourId?: string;
  isArchived?: boolean;
};

export function InsightCard({
  title,
  clusterUrl,
  asPopup,
  onClose,
  isWaitingForUpdate,
  body,
  insightData,
  selectedSessionRun,
  tourId,
  headerTourId,
  displayButtonTourId,
  filterButtonTourId,
  isArchived = false,
}: InsightCardProps): JSX.Element {
  const insightSubType =
    insightData?.insight_sub_category_ds_curation ||
    insightData?.insight_category_performance;
  const {
    globalFilters,
    displayInsight,
    setSelectedScatterInsightFilter,
    setHoveredScatterInsightFilter,
  } = useDashboardContext();

  const { archiveInsight, unarchivedInsight } = useInsightsContext();

  const [isDisplayButtonHovered, setIsDisplayButtonHovered] = useState(false);
  const displayButtonRef = useRef<HTMLDivElement>(null);

  const insightFilter = useMemo<ClusterVisualizationFilter | undefined>(() => {
    if (!clusterUrl) return;

    const displayData: InsightFilterDisplayData | undefined =
      insightData && selectedSessionRun
        ? {
            type: 'insight',
            insightData,
            sessionRun: {
              name: selectedSessionRun.name,
              id: selectedSessionRun.id,
              sessionId: selectedSessionRun.sessionId,
            },
          }
        : undefined;

    const url = trimStorageUrlProject(clusterUrl);

    return {
      field: FilterOperatorType.Cluster,
      operator: FilterOperatorType.Cluster,
      value: {
        url,
        state: 'ready',
      },
      displayData,
    };
  }, [clusterUrl, insightData, selectedSessionRun]);

  const onClickFilter = useCallback(() => {
    if (!insightFilter) {
      console.error('cant display insight', {
        insightData,
        selectedSessionRun,
      });
      return;
    }
    displayInsight(insightFilter);
  }, [displayInsight, insightData, insightFilter, selectedSessionRun]);

  const digest = useMemo(() => extractDigest(clusterUrl), [clusterUrl]);

  const onArchive = useCallback(() => {
    if (!digest || insightData?.index === undefined) {
      console.error('cant archive insight', {
        digest,
        insightData,
      });
      return;
    }
    archiveInsight(digest, insightData.index);
  }, [digest, insightData, archiveInsight]);

  const onUnarchive = useCallback(() => {
    if (!digest || insightData?.index === undefined) {
      console.error('cant unarchive insight', {
        digest,
        insightData,
      });
      return;
    }
    unarchivedInsight(digest, insightData.index);
  }, [digest, insightData, unarchivedInsight]);

  const onClickDisplay = useCallback(() => {
    if (
      !insightData?.filter.metric ||
      !insightData?.filter.value ||
      !selectedSessionRun ||
      !digest
    ) {
      console.error('cant display insight', {
        insightData,
        selectedSessionRun,
      });
      return;
    }
    setSelectedScatterInsightFilter(
      {
        key: insightData.filter.metric,
        value: insightData.filter.value,
      },
      selectedSessionRun.id,
      digest
    );
  }, [
    digest,
    insightData,
    selectedSessionRun,
    setSelectedScatterInsightFilter,
  ]);

  const handleDisplayButtonMouseEnter = useCallback(() => {
    setIsDisplayButtonHovered(true);
    if (
      insightData?.filter.metric &&
      insightData?.filter.value &&
      selectedSessionRun &&
      digest
    ) {
      setHoveredScatterInsightFilter(
        {
          key: insightData.filter.metric,
          value: insightData.filter.value,
        },
        selectedSessionRun.id,
        digest
      );
    }
  }, [digest, insightData, selectedSessionRun, setHoveredScatterInsightFilter]);

  const handleDisplayButtonMouseLeave = useCallback(() => {
    setIsDisplayButtonHovered(false);
    setHoveredScatterInsightFilter(undefined, undefined);
  }, [setHoveredScatterInsightFilter]);

  useEffect(() => {
    if (
      isDisplayButtonHovered &&
      insightData?.filter.metric &&
      insightData?.filter.value &&
      selectedSessionRun &&
      digest
    ) {
      setHoveredScatterInsightFilter(
        {
          key: insightData.filter.metric,
          value: insightData.filter.value,
        },
        selectedSessionRun.id,
        digest
      );
    } else if (!isDisplayButtonHovered) {
      setHoveredScatterInsightFilter(undefined);
    }
  }, [
    isDisplayButtonHovered,
    insightData,
    selectedSessionRun,
    setHoveredScatterInsightFilter,
    digest,
  ]);

  const isAlreadyDisplayed = useMemo(
    () => globalFilters.some((f) => f === insightFilter),
    [globalFilters, insightFilter]
  );

  return (
    <Card fixedSize={false} className="w-full insight-card" tourId={tourId}>
      <InsightHeader
        title={title}
        asPopup={asPopup}
        onClose={onClose}
        insightSubType={insightSubType}
        insightIndex={insightData?.index}
        tourId={headerTourId}
      />

      <InsightCardBody
        body={body}
        insightData={insightData}
        selectedSessionRun={selectedSessionRun}
      />

      {clusterUrl && (
        <CardFooter className="h-9">
          <ActionButton
            className="h-8"
            disabled={isWaitingForUpdate || isAlreadyDisplayed}
            icon={<FwArrowIcon />}
            onRun={onClickFilter}
            tourId={filterButtonTourId}
          >
            <Tooltip title={isWaitingForUpdate ? 'Waiting for updates' : ''}>
              <span>Filter</span>
            </Tooltip>
          </ActionButton>

          {insightData?.filter.metric !== 'None' && (
            <ActionButton
              ref={displayButtonRef}
              className="h-8"
              disabled={isWaitingForUpdate || isAlreadyDisplayed}
              icon={<Target1 />}
              onRun={onClickDisplay}
              onMouseEnter={handleDisplayButtonMouseEnter}
              onMouseLeave={handleDisplayButtonMouseLeave}
              tourId={displayButtonTourId}
            >
              <Tooltip title={isWaitingForUpdate ? 'Waiting for updates' : ''}>
                <span>Select</span>
              </Tooltip>
            </ActionButton>
          )}

          <ActionButton
            className="h-8"
            disabled={insightData?.index === undefined}
            icon={<ArchiveIcon />}
            onRun={isArchived ? onUnarchive : onArchive}
          >
            <span>{isArchived ? 'Unarchive' : 'Archive'}</span>
          </ActionButton>
        </CardFooter>
      )}
    </Card>
  );
}

function extractDigest(url?: string): string | undefined {
  if (!url) return undefined;
  const match = url.match(/digest_([a-f0-9]+)\//);
  return match ? match[1] : undefined;
}
