import { LogIcon, PauseCircledIcon, PlayIcon, XClose } from '../ui/icons';
import { useFetchJobLogs } from '../core/data-fetching/job-logs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IconButton, Tabs, Tab, Tooltip } from '@material-ui/core';
import { truncateLongtail } from '../core/formatters/string-formatting';

export interface LiveLogsProps {
  title: string;
  onClose: () => void;
  jobId: string;
}

export function LiveLogs({
  jobId,
  onClose,
  title,
}: LiveLogsProps): JSX.Element {
  const [isRunning, setIsRunning] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const logContainerRef = useRef<HTMLDivElement | null>(null);

  const { jobResponse } = useFetchJobLogs({
    jobId,
    suspense: !isRunning,
  });

  const handlePause = useCallback(() => {
    setIsRunning(false);
  }, []);

  const handleResume = useCallback(() => {
    setIsRunning(true);
  }, []);

  const handleClose = () => {
    setIsRunning(false);
    onClose();
  };

  const handleTabChange = (
    _event: React.ChangeEvent<object>,
    newValue: number
  ) => {
    setActiveTabIndex(newValue);
  };

  const podsLogs = useMemo(() => jobResponse?.podsLogs || [], [
    jobResponse?.podsLogs,
  ]);

  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [activeTabIndex, podsLogs]);

  return (
    <div className="flex flex-col w-full h-full p-3">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-1 text-gray-500 text-lg">
          <LogIcon />
          <span className="font-bold uppercase">{title}</span>
        </div>
        <div className="flex flex-row items-center gap-1 h-8">
          {isRunning ? (
            <IconButton onClick={handlePause} size="small">
              <PauseCircledIcon className="mt-1" />
            </IconButton>
          ) : (
            <IconButton onClick={handleResume} size="small">
              <PlayIcon className="h-4" />
            </IconButton>
          )}
          <IconButton onClick={handleClose} size="small">
            <XClose />
          </IconButton>
        </div>
      </div>

      <div className="flex-grow overflow-y-hidden">
        <Tabs
          value={activeTabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {podsLogs.map(({ name }, index) => (
            <Tooltip key={index} title={name}>
              <Tab
                label={truncateLongtail({
                  value: name,
                  startSubsetLength: 20,
                  endSubsetLength: 5,
                })}
              />
            </Tooltip>
          ))}
        </Tabs>

        <div
          ref={logContainerRef}
          className="h-full overflow-x-hidden overflow-y-auto break-words scrollbar-hide bg-gray-850 mt-2"
        >
          {podsLogs[activeTabIndex]?.logs.split('\n').map((line, index) => (
            <p key={index} className="px-4 py-1 text-xs">
              {line}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
