import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import useSWR from 'swr';
import {
  SampleAnalysisViz,
  SampleIdentity,
  SlimVisualization,
  VisualizedItem,
} from '@tensorleap/api-client';
import { fetchVisualization } from './fullVisualization';
import { SelectedSessionRun } from '../../ui/molecules/useModelFilter';
import api from '../../core/api-client';
import { VISUALIZATION_PAYLOAD_FILE_NAME } from '../../dashboard/ScatterDataContext';

export const SAMPLES_LOAD_BULK_SIZE = 9;
export interface SampleAnalysisVizPayloadItem {
  payload: SampleAnalysisViz;
  slimVisualization: SlimVisualization & { sampleIdentity: SampleIdentity };
}

export interface UseFullVisualizations {
  visualizations: Record<string, SampleAnalysisVizPayloadItem[]>;
  error?: Error;
  isLoading: boolean;
  refetch: () => Promise<void>;
}

export interface FetchVisPayloadsFromAnalysisProps {
  slimVisualizationsPerSessionRunId: Record<
    string,
    (SlimVisualization & { sampleIdentity: SampleIdentity })[]
  >;
  selectedSessionRuns: SelectedSessionRun[];
  mapUrl: (path: string) => string;
}

export type VisualizedItemWithIdentity = VisualizedItem & {
  sampleIdentity: SampleIdentity;
};

export interface UseFullVisualizationsFromScatter {
  visualizations: Record<string, VisualizedItemWithIdentity[]>;
  error: Error | undefined;
  isLoading: boolean;
  refetch: () => Promise<void>;
}

export interface UseFullVisualizationsFromScatterProps {
  selectedSampleIdentities: SampleIdentity[];
  samplesIdsWithAssets: Set<string>;
  scatterSampleVisualizationsPrefix: string;
  clientStoragePrefixUrl: string;
  sessionRunId: string;
  totalSamplesToLoad: number;
  handleDoneLoadSamples: () => void;
}

const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export function useFullVisualizationsFromAnalysis({
  slimVisualizationsPerSessionRunId,
  selectedSessionRuns,
  mapUrl,
}: FetchVisPayloadsFromAnalysisProps): UseFullVisualizations {
  const [visualizations, setVisualizations] = useState<
    Record<string, SampleAnalysisVizPayloadItem[]>
  >({});
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cache = useRef<Record<string, SampleAnalysisVizPayloadItem>>({});

  const debouncedSelectedSessionRuns = useDebounce(selectedSessionRuns, 300);

  const fetchSessionRunVisualizations = useCallback(
    async (
      sessionRunId: string,
      slimVisualizations: (SlimVisualization & {
        sampleIdentity: SampleIdentity;
      })[]
    ): Promise<SampleAnalysisVizPayloadItem[]> => {
      const items: SampleAnalysisVizPayloadItem[] = [];

      await Promise.all(
        slimVisualizations.map(async (visualization) => {
          const cacheKey = `${sessionRunId}_${visualization.blob}`;
          if (cache.current[cacheKey]) {
            items.push(cache.current[cacheKey]);
            return;
          }

          const blobUrl = mapUrl(visualization.blob);
          try {
            const { data } = await fetchVisualization(blobUrl, visualization);
            const item = {
              payload: data.data.payload[0] as SampleAnalysisViz,
              slimVisualization: visualization,
            };
            items.push(item);
            cache.current[cacheKey] = item;
          } catch (error) {
            console.error(
              `Error fetching visualization for session ${sessionRunId}:`,
              error
            );
          }
        })
      );

      return items;
    },
    [mapUrl]
  );

  const fetcher = useCallback(async () => {
    if (!slimVisualizationsPerSessionRunId) return {};

    const selectedSlimVisualizations = Object.fromEntries(
      Object.entries(
        slimVisualizationsPerSessionRunId
      ).filter(([sessionRunId]) =>
        debouncedSelectedSessionRuns.some(
          (sessionRun) => sessionRun.id === sessionRunId
        )
      )
    );

    const newVisualizations: Record<
      string,
      SampleAnalysisVizPayloadItem[]
    > = {};

    await Promise.all(
      Object.entries(selectedSlimVisualizations).map(
        async ([sessionRunId, slimVisualizations]) => {
          const items = await fetchSessionRunVisualizations(
            sessionRunId,
            slimVisualizations
          );
          if (items.length > 0) {
            newVisualizations[sessionRunId] = items;
          }
        }
      )
    );

    setVisualizations((prev) => ({ ...prev, ...newVisualizations }));
    return newVisualizations;
  }, [
    slimVisualizationsPerSessionRunId,
    debouncedSelectedSessionRuns,
    fetchSessionRunVisualizations,
  ]);

  const key = useMemo(
    () =>
      JSON.stringify({
        selectedSessionRuns: debouncedSelectedSessionRuns,
        slimVisualizationsPerSessionRunId,
      }),
    [debouncedSelectedSessionRuns, slimVisualizationsPerSessionRunId]
  );

  const { data, error: swrError, isValidating, mutate } = useSWR(key, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    setError(swrError);
    setIsLoading(isValidating);
  }, [swrError, isValidating]);

  const refetch = useCallback(async () => {
    setVisualizations({});
    cache.current = {};
    await mutate();
  }, [mutate]);

  return {
    visualizations: data ?? visualizations,
    error,
    isLoading,
    refetch,
  };
}

export function useFullVisualizationsFromScatter({
  selectedSampleIdentities,
  samplesIdsWithAssets,
  scatterSampleVisualizationsPrefix,
  clientStoragePrefixUrl,
  sessionRunId,
  totalSamplesToLoad,
  handleDoneLoadSamples,
}: UseFullVisualizationsFromScatterProps): UseFullVisualizationsFromScatter {
  const [visualizations, setVisualizations] = useState<
    Record<string, VisualizedItemWithIdentity[]>
  >({});
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const cache = useRef<Record<string, VisualizedItemWithIdentity[]>>({});

  const fetchSampleVisualization = useCallback(
    async (
      sampleIdentity: SampleIdentity
    ): Promise<VisualizedItemWithIdentity[]> => {
      const { state, index } = sampleIdentity;
      const sampleId = `${state}_${index}`;

      if (!samplesIdsWithAssets.has(sampleId)) return [];

      if (cache.current[sampleId]) {
        return cache.current[sampleId];
      }

      try {
        const {
          paths: sampleVisualizationPaths,
        } = await api.getSampleVisualizationsPath({
          sampleId,
          fileNameMatch: VISUALIZATION_PAYLOAD_FILE_NAME,
          scatterSampleVisualizationsPrefix,
        });

        if (!sampleVisualizationPaths?.length) {
          console.error(
            'No visualizations file paths were found for sample',
            index,
            state
          );
          return [];
        }

        const visualizedItems = await Promise.all(
          sampleVisualizationPaths.map(async (path) => {
            const fullBlobUrl = `${clientStoragePrefixUrl}\\${path}`;
            const response = await fetch(fullBlobUrl, {
              method: 'GET',
              cache: 'force-cache',
              credentials: 'include',
            });
            const visualizedItem = (await response.json()) as VisualizedItem;
            return { ...visualizedItem, sampleIdentity };
          })
        );

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cache.current[sampleId] = visualizedItems;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return visualizedItems;
      } catch (error) {
        console.error(
          `Error fetching visualizations for sample ${sampleId}:`,
          error
        );
        return [];
      }
    },
    [
      samplesIdsWithAssets,
      scatterSampleVisualizationsPrefix,
      clientStoragePrefixUrl,
    ]
  );

  const fetcher = useCallback(async () => {
    const newVisualizations: Record<string, VisualizedItemWithIdentity[]> = {};

    await Promise.all(
      selectedSampleIdentities
        .slice(0, Math.min(totalSamplesToLoad, selectedSampleIdentities.length))
        .map(async (sampleIdentity) => {
          const { state, index } = sampleIdentity;
          const sampleId = `${state}_${index}`;
          const visualizedItems = await fetchSampleVisualization(
            sampleIdentity
          );
          if (visualizedItems.length > 0) {
            newVisualizations[sampleId] = visualizedItems;
          }
        })
    );

    setVisualizations((prev) => ({ ...prev, ...newVisualizations }));
    handleDoneLoadSamples();
    return newVisualizations;
  }, [
    selectedSampleIdentities,
    fetchSampleVisualization,
    handleDoneLoadSamples,
    totalSamplesToLoad,
  ]);

  const key = useMemo(
    () =>
      `full-visualizations-${JSON.stringify(
        selectedSampleIdentities
      )}-${sessionRunId}-${totalSamplesToLoad}`,
    [selectedSampleIdentities, sessionRunId, totalSamplesToLoad]
  );

  const { data, error: swrError, isValidating, mutate } = useSWR(key, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    setError(swrError);
    setIsLoading(isValidating);
  }, [swrError, isValidating]);

  const refetch = useCallback(async () => {
    setVisualizations({});
    cache.current = {};
    await mutate();
  }, [mutate]);

  return {
    visualizations: data ?? visualizations,
    error,
    isLoading,
    refetch,
  };
}

export interface LoadMoreSamplesState {
  allowLoadMore: boolean;
  isLoading: boolean;
  handleLoadMoreSamples: () => void;
}
