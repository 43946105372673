import {
  forwardRef,
  ImgHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCurrentProject } from './CurrentProjectContext';
import { useAuth } from '../auth/AuthContext';
import { useEnvironmentInfo } from './EnvironmentInfoContext';
import clsx from 'clsx';
import { MousePosition } from './useSelectionGroup';
import { TransformComponent } from 'react-zoom-pan-pinch';

interface UseStorageUrl {
  mapUrl: (path: string) => string;
}

function calcTeamPath(teamId: string): string {
  return `organizations/${teamId}`;
}

function calcProjectPath(projectId: string): string {
  return `projects/${projectId}`;
}

type CalcEpochPathParams = {
  versionId: string;
  sessionId: string;
  sessionRunId: string;
  epoch: number;
};

export function calcEpochPath({
  versionId,
  sessionId,
  sessionRunId,
  epoch,
}: CalcEpochPathParams) {
  return `versions/${versionId}/sessions/${sessionId}/sessionruns/${sessionRunId}/epochs/${epoch}`;
}

export interface CalcFullTeamPathParams {
  clientStoragePrefixUrl: string;
  teamId: string;
}

export function calcFullTeamPath({
  clientStoragePrefixUrl,
  teamId,
}: CalcFullTeamPathParams): string {
  const teamPath = calcTeamPath(teamId);
  return `${clientStoragePrefixUrl}/${teamPath}`;
}

export function trimStorageUrlProject(url: string): string {
  return url.replace(/^.*?\/projects\/[^/]+\/?/, '');
}

export interface CalcFullProjectPathParams {
  clientStoragePrefixUrl: string;
  teamId: string;
  projectId: string;
}
export function calcFullProjectPath({
  clientStoragePrefixUrl,
  teamId,
  projectId,
}: CalcFullProjectPathParams): string {
  const fullTeamPath = calcFullTeamPath({
    clientStoragePrefixUrl,
    teamId,
  });
  const projectPath = calcProjectPath(projectId);
  return `${fullTeamPath}/${projectPath}`;
}

export function useMapProjectStorageUrl(): UseStorageUrl {
  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();
  const { currentProjectId, currentProjectTeamId } = useCurrentProject();

  const urlPrefix = useMemo(() => {
    if (!currentProjectId) {
      throw new Error('currentProjectId is not defined');
    }

    return calcFullProjectPath({
      clientStoragePrefixUrl,
      teamId: currentProjectTeamId,
      projectId: currentProjectId,
    });
  }, [clientStoragePrefixUrl, currentProjectId, currentProjectTeamId]);

  const mapUrl = useCallback(
    (path: string) => {
      if (!path.includes('/')) {
        return path;
      }

      return `${urlPrefix}/${path}`;
    },
    [urlPrefix]
  );

  return { mapUrl };
}

export function useMapTeamStorageUrl(): UseStorageUrl {
  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();
  const { user } = useAuth();
  const teamId = user?.local.teamId ?? '';

  const urlPrefix = useMemo(
    () =>
      calcFullTeamPath({
        clientStoragePrefixUrl,
        teamId,
      }),
    [clientStoragePrefixUrl, teamId]
  );

  const mapUrl = useCallback(
    (path: string) => {
      return `${urlPrefix}/${path}`;
    },
    [urlPrefix]
  );

  return { mapUrl };
}

export function useMapStoredProjectImgUrl<Src extends string | undefined>(
  pathOrSrc: Src
): Src {
  const { mapUrl } = useMapProjectStorageUrl();
  const src = useMemo(() => {
    if (!pathOrSrc || pathOrSrc.startsWith('data:')) {
      return pathOrSrc;
    }
    return mapUrl(pathOrSrc) as Src;
  }, [mapUrl, pathOrSrc]);
  return src;
}

interface PixelInfo {
  r: number;
  g: number;
  b: number;
}

export interface ImagePixelReaderProps {
  pathOrSrc: string;
  mousePosition: MousePosition;
}

export function ImagePixelReader({
  pathOrSrc,
  mousePosition,
}: ImagePixelReaderProps): JSX.Element {
  const [showDialog, setShowDialog] = useState(false);
  const src = useMapStoredProjectImgUrl(pathOrSrc);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [pixelInfo, setPixelInfo] = useState<PixelInfo>({
    r: 0,
    g: 0,
    b: 0,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d', { willReadFrequently: true });

    if (ctx && src) {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        if (canvas) {
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);
        }
      };
    }
  }, [src]);

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    setShowDialog(true);
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d', { willReadFrequently: true });

    if (!canvas || !ctx || canvas.width === 0 || canvas.height === 0) {
      return;
    }

    const rect = canvas.getBoundingClientRect();

    if (rect.width === 0 || rect.height === 0) return;

    // Calculate the aspect ratios
    const imageAspectRatio = canvas.width / canvas.height;
    const canvasAspectRatio = rect.width / rect.height;

    let widthPadding = 0;
    let heightPadding = 0;

    if (canvasAspectRatio > imageAspectRatio) {
      // Canvas is wider, so the image is constrained by height
      const renderedWidth = rect.height * imageAspectRatio;
      widthPadding = (rect.width - renderedWidth) / 2;
    } else {
      // Canvas is taller, so the image is constrained by width
      const renderedHeight = rect.width / imageAspectRatio;
      heightPadding = (rect.height - renderedHeight) / 2;
    }

    const rectImageWidth = rect.width - widthPadding * 2;
    const rectImageHeight = rect.height - heightPadding * 2;
    const scaleX = canvas.width / rectImageWidth;
    const scaleY = canvas.height / rectImageHeight;

    // Get the mouse position relative to the canvas
    let x = (e.clientX - rect.left) * scaleX;
    let y = (e.clientY - rect.top) * scaleY;

    const canvasWidthPadding = widthPadding * scaleX;
    const canvasHeightPadding = heightPadding * scaleY;

    x -= canvasWidthPadding;
    y -= canvasHeightPadding;

    // Ensure coordinates are within the canvas bounds
    if (x >= 0 && x < canvas.width && y >= 0 && y < canvas.height) {
      const imageData = ctx.getImageData(Math.floor(x), Math.floor(y), 1, 1)
        .data;

      setShowDialog(true);
      setPixelInfo({
        r: imageData[0],
        g: imageData[1],
        b: imageData[2],
      });
    } else {
      setShowDialog(false);
    }
  };

  return (
    <div className="bg-gray-900 flex-1 flex flex-row justify-center h-full w-full">
      <TransformComponent
        contentStyle={{
          height: '100%',
          width: '100%',
        }}
        wrapperStyle={{
          height: '100%',
          width: '100%',
        }}
      >
        <div className="bg-gray-900 flex-1 flex flex-row justify-center h-full w-fit object-contain m-auto">
          <canvas
            ref={canvasRef}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setShowDialog(false)}
            className="cursor-pixel object-contain max-w-full min-h-0 h-full max-h-full"
          />
        </div>
      </TransformComponent>

      <div
        className={clsx(
          'fixed flex flex-row gap-2 w-full object-contain pointer-events-none bg-transparent drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] overflow-visible',
          !showDialog && 'hidden'
        )}
        style={{
          top: mousePosition.y + 12,
          left: mousePosition.x + 14,
        }}
      >
        <div
          className="w-5 h-5 border-white border-[1px] overflow-visible"
          style={{
            backgroundColor: `rgb(${pixelInfo.r}, ${pixelInfo.g}, ${pixelInfo.b})`,
          }}
        />
        <div className="flex flex-col overflow-visible -mt-5">
          <p>R: {pixelInfo.r}</p>
          <p>G: {pixelInfo.g}</p>
          <p>B: {pixelInfo.b}</p>
        </div>
      </div>
    </div>
  );
}

export const StoredProjectImg = forwardRef<
  HTMLImageElement,
  ImgHTMLAttributes<HTMLImageElement>
>(({ src: pathOrSrc, ...props }, ref) => {
  const src = useMapStoredProjectImgUrl(pathOrSrc);
  return <img src={src} ref={ref} {...props} />;
});

StoredProjectImg.displayName = 'StoredProjectImg';
