import { AggregationMethod, OrderType } from '@tensorleap/api-client';
import { ChartSplit, DataDistributionType, UnifyGraphParams } from '../types';
import { ConfusionMatrixType } from './ConfusionMatrix';
import { AreaParams } from './Area';
import { BarParams } from './Bars';
import { DonutParams } from './Donut';
import { HeatmapParams } from './Heatmap';
import { LineParams } from './LineGraph';
import { TableParams } from './Table';
import { XYChartParams } from './XYChart';
import { DashletFields } from '../../../../core/data-fetching/dashlet-fields';

export const DEFAULT_DASHLET_FIELDS: DashletFields = {
  aggregatableFields: [],
  numericFields: [],
  notActiveFields: [],
  numericFieldsOptions: new Map(),
};

export type ExtractGraphParams = (_: UnifyGraphParams) => UnifyGraphParams;
export type GraphParamsValidator = (
  _: UnifyGraphParams
) => undefined | string[];

const barFields: (keyof BarParams)[] = [
  'aggregation',
  'xAxis',
  'dataDistribution',
  'orderBy',
  'order',
  'yAxis',
  'xAxisSizeInterval',
  'showAllEpochs',
  'autoScaleY',
  'splitSeriesBySubset',
  'splitByPrediction',
];
export const barParamsUtils = createUtils(barFields);

const areaFields: (keyof AreaParams)[] = [
  'aggregation',
  'xAxis',
  'yAxis',
  'dataDistribution',
  'orderBy',
  'order',
  'xAxisSizeInterval',
  'showAllEpochs',
  'autoScaleY',
  'splitSeriesBySubset',
  'splitByPrediction',
];
export const areaParamsUtils = createUtils(areaFields);

const lineFields: (keyof LineParams)[] = [
  'aggregation',
  'xAxis',
  'yAxis',
  'dataDistribution',
  'orderBy',
  'order',
  'xAxisSizeInterval',
  'showAllEpochs',
  'autoScaleY',
  'splitSeriesBySubset',
  'splitByPrediction',
];
export const lineParamsUtils = createUtils(lineFields);

const tableFields: (keyof TableParams)[] = [
  'metaData',
  'metrics',
  'firstSplit',
  'secondSplit',
  'showAllEpochs',
];
export const tableParamsUtils = createUtils(tableFields);

const heatmapFields: (keyof HeatmapParams)[] = [
  'color',
  'aggregation',
  'xAxisDataDistribution',
  'xAxisSizeInterval',
  'xAxisField',
  'xAxisOrder',
  'yAxisDataDistribution',
  'yAxisSizeInterval',
  'yAxisField',
  'yAxisOrder',
  'firstSplit',
  'secondSplit',
  'flipColorRange',
  'showAllEpochs',
];
export const heatmapParamsUtils = createUtils(heatmapFields);

const xyChartFields: (keyof XYChartParams)[] = [
  'aggregation',
  'xAxis',
  'yAxis',
  'dataDistribution',
  'orderBy',
  'order',
  'xAxisSizeInterval',
  'modelIdPosition',
  'firstSplit',
  'secondSplit',
  'autoScaleY',
  'showAllEpochs',
];

export const xyChartParamsUtils = createUtils(xyChartFields);

const donutFields = xyChartFields.filter(
  (f) => f !== 'autoScaleY'
) as (keyof DonutParams)[];
export const donutParamsUtils = createUtils(donutFields);

const confusionMatrixFields: (keyof ConfusionMatrixParams)[] = [
  'xAxis',
  'metricName',
  'type',
  'dataDistribution',
  'xAxisSizeInterval',
  'order',
  'orderBy',
  'modelIdPosition',
  'firstSplit',
  'secondSplit',
  'autoScaleY',
  'absAxis',
  'threshold',
  'showPercentages',
  'showAllAvgPrecisions',
  'flipColorRange',
  'confusionMatrixLabelsFilter',
  'splitByLabel',
  'splitByLabelOrder',
];

const confusionMatrixUndefinedFields: (keyof ConfusionMatrixParams)[] = [
  'threshold',
  'showPercentages',
  'showAllAvgPrecisions',
  'flipColorRange',
  'confusionMatrixLabelsFilter',
  'splitByLabel',
  'splitByLabelOrder',
];
export const confusionMatrixParamsUtils = createUtils(
  confusionMatrixFields,
  confusionMatrixUndefinedFields
);

export const splitPositions = ['vertical', 'horizontal', 'inner'] as const;
export type SplitPosition = typeof splitPositions[number];
export const SPLIT_POSITIONS_OPTIONS = Object.values(splitPositions);

export type ConfusionMatrixParams = {
  aggregation: AggregationMethod;
  dataDistribution: DataDistributionType;
  orderBy: string;
  order: OrderType;
  xAxis: string;
  metricName: string;
  type: ConfusionMatrixType;
  xAxisSizeInterval: number;
  modelIdPosition: SplitPosition;
  firstSplit: ChartSplit;
  secondSplit: ChartSplit;
  autoScaleY: boolean;
  showAllAvgPrecisions?: boolean;
  absAxis: boolean | undefined;
  threshold: number | undefined;
  showPercentages: boolean;
  flipColorRange: boolean;
  confusionMatrixLabelsFilter?: string[];
  splitByLabel?: boolean;
  splitByLabelOrder?: OrderType;
};

export type UnifiedXYChartParams = ConfusionMatrixParams &
  XYChartParams &
  TableParams &
  HeatmapParams;

function createUtils<T extends UnifyGraphParams>(
  fields: (keyof T)[],
  undefinedFields?: (keyof T)[]
) {
  const requiredFields = fields.filter(
    (f) => !undefinedFields?.includes(f)
  ) as (keyof T)[];
  return {
    extractor: (x: T): UnifyGraphParams =>
      Object.fromEntries(
        fields.map((f) => [f, x[f as keyof UnifyGraphParams]])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any,
    validator: (x: T) => {
      const missingFields = requiredFields.filter((f) => x[f] === undefined);
      return missingFields.length ? missingFields : undefined;
    },
  } as {
    extractor: ExtractGraphParams;
    validator: GraphParamsValidator;
  };
}
