import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import api from '../api-client';
import { GetJobLogsResponse } from '@tensorleap/api-client';

export interface UseFetchJobLogs {
  jobResponse?: GetJobLogsResponse;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<GetJobLogsResponse>;
}
export interface FetchJobLogsParams {
  jobId: string;
  suspense?: boolean;
}

async function fetchJobLogs({
  jobId,
}: {
  jobId: string;
}): Promise<GetJobLogsResponse> {
  const res = await api.getJobLogs({
    jobId,
  });

  return res;
}

export function useFetchJobLogs({
  jobId,
  suspense,
}: FetchJobLogsParams): UseFetchJobLogs {
  const fetchKey = `${CacheKey.JOB_LOGS}-${jobId}}`;
  const { data: response, error, mutate } = useSWR(
    fetchKey,
    async () => {
      return fetchJobLogs({ jobId });
    },
    {
      refreshInterval: suspense ? 0 : REFRESH_INTERVALS.jobLogs,
    }
  );

  return useMemo(
    () => ({
      jobResponse: response,
      error,
      isLoading: !error && !response,
      refetch: mutate,
    }),
    [error, response, mutate]
  );
}
