import { SessionRunData, SlimVersion } from '@tensorleap/api-client';
import { useVersionControl } from '../core/VersionControlContext';
import { Table } from '../ui/model-list/table/Table';
import { ModelFields } from '../ui/model-list/types';
import { Trash, PencilIcon } from '../ui/icons';
import { HoverAction } from '../ui/model-list/table/TableRowActions';
import { useCallback, useMemo, useState } from 'react';
import { AddToDashboardToggle } from './AddToDashboardToggle';
import { orderBy } from 'lodash';
import { EditableInputCell } from '../ui/atoms/EditableInputCell';
import api from '../core/api-client';
import { sessionRunTooltip } from './helper';

type ExpandedVersionRowProps = { item: SlimVersion };

export function ExpandedVersionRow({ item }: ExpandedVersionRowProps) {
  const {
    refetch,
    setSessionRunToDelete,
    setEditSessionRun,
  } = useVersionControl();

  const updateSessionRunName = useCallback(
    async (newName: string | undefined, sessionRun: SessionRunData) => {
      const projectId = sessionRun.projectId;
      const name = newName || '';

      await api.updateSessionRun({
        cid: sessionRun.cid,
        name,
        projectId,
        description: sessionRun.description,
      });

      await refetch();
    },
    [refetch]
  );

  const [isCellEditing, setIsCellEditing] = useState(false);
  const handleEditChange = useCallback((edit: boolean) => {
    setIsCellEditing(edit);
  }, []);

  const handleEditClick = useCallback(
    (sessionRun: SessionRunData) => {
      setEditSessionRun(sessionRun);
    },
    [setEditSessionRun]
  );

  const MODEL_FIELDS: ModelFields<SessionRunData> = useMemo(
    () => [
      {
        label: 'runs',
        table: { headerClassName: 'text-gray-500' },
        format(sessionRun: SessionRunData) {
          return (
            <EditableInputCell
              value={sessionRun.name}
              onChange={(value) => updateSessionRunName(value, sessionRun)}
              textWhenNotEditable={true}
              tooltip={sessionRunTooltip(sessionRun)}
              onEditChange={handleEditChange}
              className="w-[230px]"
            />
          );
        },
      },
    ],
    [handleEditChange, updateSessionRunName]
  );

  const hoverActions = useMemo<HoverAction<SessionRunData>[]>(() => {
    return [
      {
        icon: <Trash className="text-gray-300" />,
        title: 'Delete Run',
        onSelect: setSessionRunToDelete,
      },
      {
        icon: <PencilIcon className="text-gray-300" />,
        title: 'Edit Run',
        onSelect: handleEditClick,
      },
    ];
  }, [setSessionRunToDelete, handleEditClick]);

  const renderToggleModel = useCallback((sessionRun: SessionRunData) => {
    return <AddToDashboardToggle sessionRun={sessionRun} />;
  }, []);

  const orderedSessionRuns = useMemo(
    () =>
      orderBy(
        item.sessions
          .flatMap((s) => s.sessionRuns)
          .filter((sr) => sr) as SessionRunData[],
        ({ createdAt }) => createdAt,
        'desc'
      ),
    [item.sessions]
  );

  return (
    <>
      <Table
        tableBgClass="bg-gray-850"
        headersBgClass="bg-gray-900 !z-0"
        inline
        fields={MODEL_FIELDS}
        data={orderedSessionRuns}
        customActions={renderToggleModel}
        hoverActions={hoverActions}
        showHoverActions={!isCellEditing}
      />
    </>
  );
}
