import { TooltipProps } from '@material-ui/core';
import { FilterForm, FilterFormProps } from './FilterForm';
import { FilterFieldMeta } from './helpers';
import { InteractiveTooltip } from '../ui/molecules/InteractiveTooltip';
import clsx from 'clsx';

export type FilterFormPopupProps = FilterFormProps & {
  children: TooltipProps['children'];
  onClose: () => void;
  fields: FilterFieldMeta[];
  open: boolean;
  allowDisable?: boolean;
};

export function FilterFormPopup({
  children,
  open,
  fields,
  defaultFilter,
  onClose,
  onApply,
  allowDisable = true,
}: FilterFormPopupProps) {
  return (
    <InteractiveTooltip
      contentClass={clsx(
        'w-fit h-fit px-4 py-6 bg-gray-850 border-2 border-gray-800 shadow-4c'
      )}
      arrowClass="text-gray-800"
      open={open}
      onClose={onClose}
      content={
        <FilterForm
          defaultFilter={defaultFilter}
          onClose={onClose}
          onApply={onApply}
          fields={fields}
          allowDisable={allowDisable}
        />
      }
    >
      {children}
    </InteractiveTooltip>
  );
}
