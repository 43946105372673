import { useCallback, useMemo } from 'react';
import { useVersionControl } from '../core/VersionControlContext';
import { useHistory } from 'react-router';
import { removeVersionUrl } from '../url/url-builder';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';

export function DeleteVersionDialog() {
  const { currentVersionId } = useCurrentProject();

  const {
    versionToDelete,
    setVersionToDelete,
    deleteVersion,
  } = useVersionControl();

  console.info({ versionToDelete });

  const history = useHistory();

  const handleClose = useCallback(() => setVersionToDelete(undefined), [
    setVersionToDelete,
  ]);

  const handleConfirmDeleteVersion = useCallback(async () => {
    try {
      versionToDelete && (await deleteVersion(versionToDelete.cid));
    } catch (err) {
      console.error(err);
    }
    if (versionToDelete && versionToDelete?.cid === currentVersionId) {
      history.push({
        pathname: removeVersionUrl(location.pathname),
        search: location.search,
      });
    }
    handleClose();
  }, [currentVersionId, deleteVersion, handleClose, history, versionToDelete]);

  const deleteVersionDialogTitle = useMemo(
    () =>
      `Are you sure you want to delete ${
        versionToDelete?.notes || 'this'
      } version?`,

    [versionToDelete?.notes]
  );

  return (
    <ConfirmDialog
      title={deleteVersionDialogTitle}
      isOpen={!!versionToDelete}
      onClose={handleClose}
      onConfirm={handleConfirmDeleteVersion}
    />
  );
}
