import { forwardRef, PropsWithChildren, ReactNode, useMemo } from 'react';
import {
  Notification,
  MessageLevel,
  JobNotificationModelContext,
  JobNotificationLeepScriptContext,
  JobNotificationContext,
  CustomMessageData,
  JobMessageParams,
  JobStatus,
  JobNotificationSampleContext,
} from '@tensorleap/api-client';
import clsx from 'clsx';
import {
  Alert,
  Alerts,
  ErrorIcon,
  Eta,
  NoProblemIcon,
  PlayIcon,
} from '../icons';
import { ErrorBoundary } from '../../core/ErrorBoundary';
import { Card, CardHeader } from './Card';
import { isJobStatusMessageParams } from '../../core/websocket-message-types';
import { Divider } from '../../dashboard/Divider';
import { ClassNameProp } from '../../core/types';
import { truncateLongtail } from '../../core/formatters/string-formatting';
import { CodeTextArea } from '../atoms/CodeTextArea';
import { Truncate } from '../atoms/Truncate';

type NofificationStyle = {
  headerIcon: typeof Alerts;
  headerClassName: string;
  contextClassName: string;
};

type JobStatusWithSpcialStyle =
  | JobStatus.Failed
  | JobStatus.Pending
  | JobStatus.Finished
  | JobStatus.Started;

const STYLE_BY_JOB_STATUS_MAP: Record<
  JobStatusWithSpcialStyle,
  NofificationStyle
> = {
  [JobStatus.Failed]: {
    headerIcon: Alert,
    headerClassName: 'bg-error-500',
    contextClassName: 'bg-error-950',
  },
  [JobStatus.Started]: {
    headerIcon: PlayIcon,
    headerClassName: 'bg-primary-600',
    contextClassName: 'bg-primary-950',
  },
  [JobStatus.Finished]: {
    headerIcon: NoProblemIcon,
    headerClassName: 'bg-success-500',
    contextClassName: 'bg-success-950',
  },
  [JobStatus.Pending]: {
    headerIcon: Eta,
    headerClassName: 'bg-gray-600',
    contextClassName: 'bg-gray-850',
  },
};

const STYLE_BY_NOTIFICATION_LEVEL_MAP: Record<
  MessageLevel,
  NofificationStyle
> = {
  [MessageLevel.Verbose]: {
    headerIcon: Alerts,
    headerClassName: 'bg-gray-600',
    contextClassName: 'bg-gray-850',
  },
  [MessageLevel.Info]: {
    headerIcon: Alerts,
    headerClassName: 'bg-primary-600',
    contextClassName: 'bg-primary-950',
  },
  [MessageLevel.Warning]: {
    headerIcon: Alert,
    headerClassName: 'bg-warning-500',
    contextClassName: 'bg-warning-950',
  },
  [MessageLevel.Error]: {
    headerIcon: ErrorIcon,
    headerClassName: 'bg-error-500',
    contextClassName: 'bg-error-950',
  },
};

function getNotificationStyle({
  level,
  params,
}: CustomMessageData): NofificationStyle {
  const style = STYLE_BY_NOTIFICATION_LEVEL_MAP[level];

  return (
    STYLE_BY_JOB_STATUS_MAP[
      (params as JobMessageParams)?.jobStatus as JobStatusWithSpcialStyle
    ] || style
  );
}

function mapTitle(title: string, { params }: CustomMessageData) {
  title = title.replaceAll('_', ' ');
  if ((params as JobMessageParams)?.jobStatus)
    return `${title} ${(params as JobMessageParams)?.jobStatus}`;
  return title;
}

export interface NotificationCardProps
  extends Pick<Notification, 'messageData' | 'title' | 'context'> {
  createdAt?: Date | string;
  isRead?: boolean;
  cardClassName?: string;
  menu?: ReactNode;
}
export const NotificationCard = forwardRef<
  HTMLDivElement,
  NotificationCardProps
>(
  (
    {
      title,
      context,
      messageData,
      createdAt,
      isRead = false,
      cardClassName,
      menu,
    },
    ref
  ) => {
    const { params } = messageData;

    const {
      headerIcon: Icon,
      contextClassName,
      headerClassName,
    } = useMemo(() => getNotificationStyle(messageData), [messageData]);
    const mappedTitle = useMemo(() => mapTitle(title, messageData), [
      title,
      messageData,
    ]);
    const module = params.module;

    let textSection = '';
    let codeSection = '';

    if (isJobStatusMessageParams(params)) {
      textSection = params.jobStatus;
    } else {
      const { message = '', message_code = '' } = params;
      textSection = message;
      codeSection = message_code;
    }

    return (
      <ErrorBoundary>
        <Card
          className={clsx(cardClassName, 'wrap', isRead && 'brightness-75')}
          ref={ref}
        >
          <CardHeader
            small
            className={clsx('font-bold min-w-full text-2xs', headerClassName)}
            icon={<Icon className="text-base" />}
            title={mappedTitle}
            action={menu}
          />

          {isSampleCtx(context) ? (
            <NotificationSampleContext
              className={contextClassName}
              {...context}
            />
          ) : isModelCtx(context) ? (
            <NotificationModelContext
              className={contextClassName}
              {...context}
            />
          ) : (
            isLeepScriptCtx(context) && (
              <NotificationLeepScriptContext
                className={contextClassName}
                {...context}
              />
            )
          )}

          <p className="px-4 py-2 text-gray-200 text-2xs overflow-y-auto max-h-80 break-words rounded-b-xl">
            <span>{textSection}</span>
            {createdAt && module === 'JobStatus' && (
              <span className="pl-1">
                @ {new Date(createdAt).toLocaleString()}
              </span>
            )}
          </p>

          {!!codeSection && (
            <CodeTextArea
              text={codeSection}
              containerClassName="mx-4 mb-4"
              className="max-h-32"
            />
          )}
        </Card>
      </ErrorBoundary>
    );
  }
);

NotificationCard.displayName = 'NotificationCard';

function isSampleCtx(
  ctx: JobNotificationContext
): ctx is JobNotificationSampleContext {
  return !!(ctx as JobNotificationSampleContext)?.sample;
}

function isModelCtx(
  ctx: JobNotificationContext
): ctx is JobNotificationModelContext {
  return !!(ctx as JobNotificationModelContext)?.modelName;
}

function isLeepScriptCtx(
  ctx: JobNotificationContext
): ctx is JobNotificationLeepScriptContext {
  return !!(ctx as JobNotificationLeepScriptContext)?.leepScriptName;
}

type NotificationModelContextProps = JobNotificationModelContext &
  ClassNameProp;
function NotificationModelContext({
  className,
  projectName,
  modelName,
}: NotificationModelContextProps) {
  return (
    <NotificationContextWrapper className={className}>
      <div className="flex flex-row max-w-full overflow-hidden">
        <Truncate
          className="max-w-[48%] font-bold uppercase"
          value={projectName}
        />
        <Divider small vertical />
        <Truncate value={modelName} />
      </div>
    </NotificationContextWrapper>
  );
}

type NotificationSampleContextProps = JobNotificationSampleContext &
  ClassNameProp;
function NotificationSampleContext({
  className,
  projectName,
  modelName,
  sample,
}: NotificationSampleContextProps) {
  return (
    <NotificationContextWrapper className={className}>
      <span className="font-bold uppercase">{projectName}</span>
      <Divider small vertical />
      <span>
        {truncateLongtail({
          value: modelName,
          startSubsetLength: 10,
          endSubsetLength: 10,
        })}
      </span>
      <Divider small vertical />
      <span className="uppercase">
        {sample.state} #{sample.index}
      </span>
    </NotificationContextWrapper>
  );
}

type NotificationLeepScriptContextProps = JobNotificationLeepScriptContext &
  ClassNameProp;
function NotificationLeepScriptContext({
  className,
  leepScriptName,
  leepScriptVersion,
}: NotificationLeepScriptContextProps) {
  return (
    <NotificationContextWrapper className={className}>
      <span className="font-bold uppercase">{leepScriptName}</span>
      {leepScriptVersion && (
        <>
          <Divider small vertical />
          <span className="text-gray-500">{leepScriptVersion}</span>{' '}
        </>
      )}
    </NotificationContextWrapper>
  );
}

function NotificationContextWrapper({
  className,
  children,
}: PropsWithChildren<ClassNameProp>) {
  return (
    <div
      className={clsx(
        'h-8 px-4 flex items-center text-2xs border-t border-t-gray-800 text-gray-200 overflow-auto whitespace-nowrap',
        className
      )}
    >
      {children}
    </div>
  );
}
