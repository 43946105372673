import {
  AnalyticsDashlet,
  AnalyticsDashletType,
  Dashboard,
  UpdateDashboardParams,
  Dashlet,
  SampleAnalysisDashlet,
  PopulationExplorationDashlet,
  AnalyticsDashletData,
  NumberOrString,
} from '@tensorleap/api-client';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ActionResult, Setter } from '../core/types';
import api from '../core/api-client';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { useMergedObject } from '../core/useMergedObject';
import {
  ClusterVisualizationFilter,
  VisualizationFilter,
} from '../core/types/filters';
import { isInsightFilter } from '../core/filters';
import { calcNewDashletLayout, organizeDashboardItems } from './utils';
import { useHistory, useLocation } from 'react-router';
import {
  calcLocalStorageDashStateKey,
  DASHBOARD_STATE_KEY,
  deleteQueryParam,
  SELECTED_DASHBOARD_KEY,
  setQueryParam,
  STATE_KEYS,
} from '../url/url-builder';
import { useFetchDashboards } from '../core/data-fetching/dashboards';
import { LocalFiltersMap } from '../ui/DashboardAndNetworkTabsLoader';
import { useProjectURLState } from '../core/ProjectURLStateContext';

export type SetLocalFilterFunc = (filter: VisualizationFilter) => void;

export function dashboardToUpdateParams({
  name,
  cid,
  description,
  items,
  projectId,
  ignoreSuggestedDashletsHashes,
}: Dashboard): UpdateDashboardParams {
  return {
    name,
    projectId,
    dashboardId: cid,
    description,
    items,
    ignoreSuggestedDashletsHashes,
  };
}

export enum DashletType {
  Analytics = 'Analytics',
  SampleAnalysis = 'SampleAnalysis',
  PopulationExploration = 'PopulationExploration',
}
export type AddDashlet = (
  type: DashletType,
  data?: AnalyticsDashletData
) => Promise<string | undefined>;
export type AddDashboardProps = { name: string; description?: string };

export interface FilterKvp {
  key: string;
  value: NumberOrString;
}
export interface InsightScatterSelectionFilter {
  selected?: {
    filter: FilterKvp;
    sessionRunId: string;
    digest: string;
  };
  hovered?: {
    filter: FilterKvp;
    sessionRunId: string;
    digest: string;
  };
}

export type DashboardContextType = {
  addDashlet: AddDashlet;
  removeDashlet(dashboardId: string, visualId: string): ActionResult;
  duplicateDashlet(dashboardId: string, visualId: string): ActionResult;
  updateDashlet(id: string, _: Dashlet): ActionResult;
  addDashboard(props: AddDashboardProps): ActionResult<string | undefined>;
  switchDashboard(dashboardId?: string): void;
  updateDashboard(dashboard: UpdateDashboardParams): ActionResult;
  removeDashboard(id?: string): ActionResult;
  duplicateDashboard(id?: string): ActionResult;
  ignoreSuggestion: (hash: string) => void;
  resetIgnoreSuggestion: () => Promise<void>;
  ignoreSuggestionHashes: string[];
  selected?: string;
  dashletsInEditMode?: string[];
  setDashletsInEditMode: Dispatch<SetStateAction<string[] | undefined>>;
  localEpochFilter?: number;
  setLocalEpochFilter: (epoch?: number) => void;
  globalFilters: VisualizationFilter[];
  getDashletUserData: (dashletId: string) => VisualizationFilter[];
  handleLocalFiltersChange: (
    dashletId: string,
    filters: VisualizationFilter[]
  ) => void;
  handleGlobalFiltersChange: (filters: VisualizationFilter[]) => void;
  globalizeFilters: (dashletId: string) => void;
  displayInsight: (insightFilter: ClusterVisualizationFilter) => void;
  displayPreviousInsight: () => void;
  displayedInsight?: ClusterVisualizationFilter;
  clearDisplayedInsightsHistory: () => void;
  setReactGridLayoutRef: Dispatch<RefObject<HTMLDivElement>>;
  organizeDashboard: () => Promise<void>;
  isAddDashletsOpen: boolean;
  setIsAddDashletsOpen: Setter<boolean>;
  insightScatterSelectionFilter?: InsightScatterSelectionFilter;
  setSelectedScatterInsightFilter: (
    selected?: FilterKvp,
    selectedSessionRun?: string,
    digest?: string
  ) => void;
  setHoveredScatterInsightFilter: (
    hovered?: FilterKvp,
    selectedSessionRun?: string,
    digest?: string
  ) => void;
};

const DEFAULT_VALUES: DashboardContextType = {
  addDashlet: () => Promise.resolve(''),
  removeDashlet: () => Promise.resolve(undefined),
  duplicateDashlet: () => Promise.resolve(undefined),
  updateDashlet: () => Promise.resolve(undefined),
  addDashboard: () => Promise.resolve(undefined),
  switchDashboard: () => undefined,
  updateDashboard: () => Promise.resolve(undefined),
  removeDashboard: () => Promise.resolve(undefined),
  duplicateDashboard: () => Promise.resolve(undefined),
  setDashletsInEditMode: () => undefined,
  localEpochFilter: undefined,
  setLocalEpochFilter: () => undefined,
  globalFilters: [],
  globalizeFilters: () => undefined,
  handleGlobalFiltersChange: () => undefined,
  getDashletUserData: () => [],
  handleLocalFiltersChange: () => undefined,
  displayInsight: () => undefined,
  displayPreviousInsight: () => undefined,
  clearDisplayedInsightsHistory: () => undefined,
  setReactGridLayoutRef: () => undefined,
  organizeDashboard: () => Promise.resolve(),
  ignoreSuggestion: () => undefined,
  resetIgnoreSuggestion: () => Promise.resolve(),
  ignoreSuggestionHashes: [],
  setIsAddDashletsOpen: () => undefined,
  isAddDashletsOpen: false,
  insightScatterSelectionFilter: undefined,
  setSelectedScatterInsightFilter: () => undefined,
  setHoveredScatterInsightFilter: () => undefined,
};

export type DashboardApi = Pick<
  typeof api,
  | 'updateDashboard'
  | 'addDashboard'
  | 'deleteDashboard'
  | 'getProjectDashboards'
>;
api.getProjectDashboards = api.getProjectDashboards.bind(api);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DashboardContext = createContext<DashboardContextType>(DEFAULT_VALUES);

export function DashboardContextProvider({
  children,
  dashboardApi = api,
  dashboardId,
}: PropsWithChildren<{
  dashboardApi?: DashboardApi;
  dashboardId?: string;
}>) {
  const [dashletsInEditMode, setDashletsInEditMode] = useState<string[]>();
  const { fetchValidProjectCid } = useCurrentProject();
  const { projectState, handleDashboardStateChange } = useProjectURLState();
  const projectId = fetchValidProjectCid();
  const [displayedInsightsStack, setDisplayedInsightsStack] = useState<
    ClusterVisualizationFilter[]
  >([]);

  const history = useHistory();
  const { pathname } = useLocation();

  const [reactGridLayoutRef, setReactGridLayoutRef] = useState<
    RefObject<HTMLDivElement>
  >();

  const handleGlobalFiltersChange = useCallback(
    async (globalFilters: VisualizationFilter[]) => {
      if (!dashboardId) {
        console.error('No dashboard id, should not happen');
        return;
      }

      await handleDashboardStateChange({
        newValues: [
          { stateKey: STATE_KEYS.GLOBAL_FILTERS, value: globalFilters },
        ],
        dashboardId,
      });
    },
    [dashboardId, handleDashboardStateChange]
  );

  const { localFiltersMap, globalFilters } = useMemo(() => {
    if (!dashboardId) {
      return {
        localFiltersMap: {} as LocalFiltersMap,
        globalFilters: [] as VisualizationFilter[],
      };
    }
    const localFiltersMap =
      (projectState?.['dashboards']?.[dashboardId]?.[
        STATE_KEYS.LOCAL_FILTERS_MAP
      ] as LocalFiltersMap) || {};

    const globalFilters =
      (projectState?.['dashboards']?.[dashboardId]?.[
        STATE_KEYS.GLOBAL_FILTERS
      ] as VisualizationFilter[]) || [];

    return { localFiltersMap, globalFilters };
  }, [projectState, dashboardId]);

  const getDashletLocalFilters = useCallback(
    (dashletId: string) => localFiltersMap[dashletId] || [],
    [localFiltersMap]
  );

  const handleLocalFiltersChange = useCallback(
    (dashletId: string, filters: VisualizationFilter[]) => {
      if (!dashboardId) {
        console.error('No dashboard id, should not happen');
        return;
      }

      localFiltersMap[dashletId] = filters;
      handleDashboardStateChange({
        newValues: [
          { stateKey: STATE_KEYS.LOCAL_FILTERS_MAP, value: localFiltersMap },
        ],
        dashboardId,
      });
    },
    [localFiltersMap, handleDashboardStateChange, dashboardId]
  );

  const globalizeFilters = useCallback(
    (dashletId: string) => {
      if (!dashboardId) {
        console.error('No dashboard id, should not happen');
        return;
      }

      const localFilters = localFiltersMap[dashletId] || [];
      localFiltersMap[dashletId] = [];
      handleDashboardStateChange({
        newValues: [
          {
            stateKey: STATE_KEYS.GLOBAL_FILTERS,
            value: [...globalFilters, ...localFilters],
          },
          {
            stateKey: STATE_KEYS.LOCAL_FILTERS_MAP,
            value: localFiltersMap,
          },
        ],
        dashboardId,
      });
    },
    [dashboardId, localFiltersMap, handleDashboardStateChange, globalFilters]
  );

  const replaceDisplayedInsightOnGlobalFilters = useCallback(
    (insightFilter: ClusterVisualizationFilter | undefined) => {
      const prevWithoutInsight = globalFilters.filter(
        (f) => !isInsightFilter(f)
      );
      handleGlobalFiltersChange(
        insightFilter
          ? [...prevWithoutInsight, insightFilter]
          : prevWithoutInsight
      );
    },
    [globalFilters, handleGlobalFiltersChange]
  );

  const displayInsight = useCallback(
    (insightFilter: ClusterVisualizationFilter) => {
      setDisplayedInsightsStack((prev) => [...prev, insightFilter]);
      replaceDisplayedInsightOnGlobalFilters(insightFilter);
    },
    [replaceDisplayedInsightOnGlobalFilters]
  );

  const displayPreviousInsight = useCallback(() => {
    setDisplayedInsightsStack((prev) => prev.slice(0, prev.length - 1));

    replaceDisplayedInsightOnGlobalFilters(
      displayedInsightsStack[displayedInsightsStack.length - 2]
    );
  }, [displayedInsightsStack, replaceDisplayedInsightOnGlobalFilters]);

  const displayedInsight = displayedInsightsStack.length
    ? displayedInsightsStack[displayedInsightsStack.length - 1]
    : undefined;

  const clearDisplayedInsightsHistory = useCallback(() => {
    setDisplayedInsightsStack([]);
  }, [setDisplayedInsightsStack]);

  const { dashboards, refetch } = useFetchDashboards({
    projectId,
  });

  const scrollToBottom = useCallback(() => {
    reactGridLayoutRef?.current?.scrollTo({
      top: reactGridLayoutRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [reactGridLayoutRef]);

  const switchDashboard = useCallback(
    (dashboardId?: string) => {
      if (!dashboardId) {
        console.error('No dashboard selected');
        return;
      }

      const search = new URLSearchParams(window.location.search).toString();
      const newSearch = deleteQueryParam(
        setQueryParam(search, SELECTED_DASHBOARD_KEY, dashboardId),
        DASHBOARD_STATE_KEY
      );
      history.push({
        pathname,
        search: newSearch,
      });
    },
    [history, pathname]
  );

  const actions = useMemo(
    () => ({
      async addDashlet(dashletType: DashletType, data?: AnalyticsDashletData) {
        const dashboard = dashboards?.find(({ cid }) => cid === dashboardId);
        if (!dashboard) {
          console.error(`Unknown dashboard id: ${dashboardId}`);
          return;
        }
        const uid = Date.now().toString();

        let newDashlet: Dashlet;

        const newDashletLayout = calcNewDashletLayout(dashboard.items);

        switch (dashletType) {
          case DashletType.Analytics:
            newDashlet = {
              cid: uid,
              type: 'Analytics',
              layout: newDashletLayout,
              data: data ?? {
                name: '',
                type: AnalyticsDashletType.Line,
                data: {},
              },
            } as AnalyticsDashlet;
            break;
          case DashletType.SampleAnalysis:
            newDashlet = {
              cid: uid,
              type: 'SampleAnalysis',
              layout: newDashletLayout,
              name: '',
              collectionIds: [],
            } as SampleAnalysisDashlet;

            break;
          case DashletType.PopulationExploration:
            newDashlet = {
              cid: uid,
              type: 'PopulationExploration',
              layout: newDashletLayout,
              name: '',
              data: {
                name: '',
                type: 'PopulationExploration',
                data: {},
              },
            } as PopulationExplorationDashlet;

            break;

          default:
            throw new Error(`Unknown dashlet type: ${dashletType}`);
        }

        await dashboardApi.updateDashboard(
          dashboardToUpdateParams({
            ...dashboard,
            items: [...dashboard.items, newDashlet],
          })
        );
        await refetch();
        setDashletsInEditMode((prev) => [...(prev ?? []), uid]);

        scrollToBottom();

        return uid;
      },

      async removeDashlet(dashboardId: string, removeId: string) {
        const dashboard = dashboards?.find(({ cid }) => cid === dashboardId);
        if (!dashboard) return;
        await dashboardApi.updateDashboard(
          dashboardToUpdateParams({
            ...dashboard,
            items: dashboard.items.filter(({ cid }) => cid !== removeId),
          })
        );
        await refetch();
      },

      async resetIgnoreSuggestion() {
        const dashboard = dashboards?.find(({ cid }) => cid === dashboardId);
        if (!dashboard) {
          console.error(`Unknown dashboard id: ${dashboardId}`);
          return;
        }
        await dashboardApi.updateDashboard(
          dashboardToUpdateParams({
            ...dashboard,
            ignoreSuggestedDashletsHashes: [],
          })
        );
        await refetch();
      },

      async ignoreSuggestion(hash: string) {
        const dashboard = dashboards?.find(({ cid }) => cid === dashboardId);
        if (!dashboard) {
          console.error(`Unknown dashboard id: ${dashboardId}`);
          return;
        }
        await dashboardApi.updateDashboard(
          dashboardToUpdateParams({
            ...dashboard,
            ignoreSuggestedDashletsHashes: [
              ...dashboard.ignoreSuggestedDashletsHashes,
              hash,
            ],
          })
        );
        await refetch();
      },

      async duplicateDashlet(dashboardId: string, duplicateId: string) {
        const dashboard = dashboards?.find(({ cid }) => cid === dashboardId);
        if (!dashboard) {
          console.error(`Unknown dashboard id: ${dashboardId}`);
          return;
        }

        const dashletToDuplicate = dashboard.items.find(
          ({ cid }) => cid === duplicateId
        );

        if (!dashletToDuplicate) {
          console.error(`Unknown dashlet id: ${duplicateId}`);
          return;
        }

        const newDashletLayout = calcNewDashletLayout(dashboard.items);
        const newCid = Date.now().toString();
        const duplicatedDashlet: Dashlet = {
          ...dashletToDuplicate,
          cid: newCid,
          layout: newDashletLayout,
        };

        handleLocalFiltersChange(newCid, getDashletLocalFilters(duplicateId));

        await dashboardApi.updateDashboard(
          dashboardToUpdateParams({
            ...dashboard,
            items: [...dashboard.items, duplicatedDashlet],
          })
        );
        await refetch();

        scrollToBottom();
      },

      async updateDashlet(dashboardId: string, dashlet: Dashlet) {
        const dashboard = dashboards?.find(({ cid }) => cid === dashboardId);
        if (
          !dashboard ||
          dashboard.items.every(({ cid }) => cid !== dashlet.cid)
        ) {
          console.warn(
            `Unknown dashlet id: ${dashlet.cid} on dashboard id: ${dashboardId}`
          );
          return;
        }
        await dashboardApi.updateDashboard(
          dashboardToUpdateParams({
            ...dashboard,
            items: dashboard.items.map((d) =>
              d.cid === dashlet.cid ? dashlet : d
            ),
          })
        );
        await refetch();
      },

      async addDashboard(props: AddDashboardProps) {
        if (!projectId) return;
        const { dashboardId: newDashboardId } = await dashboardApi.addDashboard(
          {
            projectId,
            items: [],
            ...props,
          }
        );
        await refetch();
        console.info('Added dashboard:', newDashboardId);
        switchDashboard(newDashboardId);

        return dashboardId;
      },

      async duplicateDashboard(_dashboardId = dashboardId) {
        const dashboard = dashboards?.find(({ cid }) => cid === _dashboardId);
        if (!dashboard) {
          console.error(`Unknown dashboard id: ${_dashboardId}`);
          return;
        }

        const clonedItems: Dashlet[] = dashboard.items.map((item, index) => ({
          ...item,
          cid: `${Date.now().toString() + index}`,
        }));
        const {
          dashboardId: duplicatedDashboardId,
        } = await dashboardApi.addDashboard({
          projectId,
          items: clonedItems,
          name: `${dashboard.name} (copy)`,
          description: dashboard.description,
        });

        const localFiltersMap = clonedItems.reduce<LocalFiltersMap>(
          (acc, { cid }, index) => ({
            ...acc,
            [cid]: getDashletLocalFilters(dashboard.items[index].cid),
          }),
          {}
        );

        await handleDashboardStateChange({
          newValues: [
            { stateKey: STATE_KEYS.LOCAL_FILTERS_MAP, value: localFiltersMap },
            { stateKey: STATE_KEYS.GLOBAL_FILTERS, value: globalFilters },
          ],
          dashboardId: duplicatedDashboardId,
        });

        await refetch();

        switchDashboard(duplicatedDashboardId);
      },

      async removeDashboard(_dashboardId = dashboardId) {
        if (!_dashboardId) {
          return;
        }

        await handleDashboardStateChange({
          newValues: [],
          dashboardId: _dashboardId,
        });

        const dashStateLocalStorageKey = calcLocalStorageDashStateKey(
          _dashboardId
        );
        localStorage.removeItem(dashStateLocalStorageKey);

        await dashboardApi.deleteDashboard({
          dashboardId: _dashboardId,
          projectId: projectId || '',
        });
        await refetch();
      },

      async updateDashboard(dashboard: UpdateDashboardParams) {
        await dashboardApi.updateDashboard(dashboard);
        await refetch();
      },
    }),
    [
      dashboards,
      dashboardApi,
      refetch,
      scrollToBottom,
      dashboardId,
      handleLocalFiltersChange,
      getDashletLocalFilters,
      projectId,
      switchDashboard,
      handleDashboardStateChange,
      globalFilters,
    ]
  );

  const [localEpochFilter, setLocalEpochFilter] = useState<number>();

  const organizeDashboard = useCallback(async () => {
    const dashboard = dashboards?.find(({ cid }) => cid === dashboardId);
    if (!dashboard) {
      console.error(`Unknown dashboard id: ${dashboardId}`);
      return;
    }

    await dashboardApi.updateDashboard(
      dashboardToUpdateParams({
        ...dashboard,
        items: organizeDashboardItems(dashboard.items),
      })
    );
    await refetch();
    setDashletsInEditMode([]);
  }, [dashboardApi, dashboards, refetch, dashboardId]);

  const ignoreSuggestionHashes = useMemo(
    () =>
      dashboards?.find(({ cid }) => cid === dashboardId)
        ?.ignoreSuggestedDashletsHashes ?? [],
    [dashboards, dashboardId]
  );

  const [isAddDashletsOpen, setIsAddDashletsOpen] = useState(false);

  const [
    insightScatterSelectionFilter,
    setInsightScatterSelectionFilterInternal,
  ] = useState<InsightScatterSelectionFilter>();

  const setSelectedScatterInsightFilter = useCallback(
    (selected?: FilterKvp, sessionRunId?: string, digest?: string) => {
      setInsightScatterSelectionFilterInternal((prev) => {
        if (!selected || !sessionRunId || !digest) {
          return prev ? { ...prev, selected: undefined } : undefined;
        }
        return {
          ...prev,
          selected: { filter: selected, sessionRunId, digest },
        };
      });
    },
    []
  );

  const setHoveredScatterInsightFilter = useCallback(
    (hovered?: FilterKvp, sessionRunId?: string, digest?: string) => {
      setInsightScatterSelectionFilterInternal((prev) => {
        if (!hovered || !sessionRunId || !digest) {
          return prev ? { ...prev, hovered: undefined } : undefined;
        }
        return {
          ...prev,
          hovered: { filter: hovered, sessionRunId, digest },
        };
      });
    },
    []
  );

  const value = useMergedObject({
    ...actions,
    switchDashboard,
    selected: dashboardId,
    dashletsInEditMode,
    setDashletsInEditMode,
    localEpochFilter,
    setLocalEpochFilter,
    globalFilters,
    getDashletUserData: getDashletLocalFilters,
    handleLocalFiltersChange,
    handleGlobalFiltersChange,
    globalizeFilters,
    displayInsight,
    displayPreviousInsight,
    displayedInsight,
    clearDisplayedInsightsHistory,
    setReactGridLayoutRef,
    organizeDashboard,
    ignoreSuggestionHashes,
    isAddDashletsOpen,
    setIsAddDashletsOpen,
    insightScatterSelectionFilter,
    setSelectedScatterInsightFilter,
    setHoveredScatterInsightFilter,
  });

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboardContext() {
  return useContext(DashboardContext);
}
