import { PropsWithChildren } from 'react';
import { Chip } from './Chip';
import { ClassNameProp } from '../../core/types';

export function PlaceholderChip({
  children,
  className,
  tourId,
}: PropsWithChildren<ClassNameProp & { tourId?: string }>) {
  return (
    <Chip
      className={className}
      borderClassName="bg-transparent border-dashed text-white/40 border-gray-600"
      tourId={tourId}
    >
      {children}
    </Chip>
  );
}
