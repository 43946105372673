import { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';

export interface TitleProps {
  ultraSmall?: boolean;
  small?: boolean;
  bottomBorderClassName?: string;
  className?: string;
  children: ReactNode;
}
export function Title({
  ultraSmall,
  small,
  children,
  bottomBorderClassName = 'border-b-warning-500',
  className,
}: PropsWithChildren<TitleProps>): JSX.Element {
  const sizedClassName = ultraSmall
    ? 'border-b-2 text-xs font-bold'
    : small
    ? 'border-b-2 text-sm font-bold'
    : 'pb-1 border-b-2 text-xl font-black';

  return (
    <div className={className}>
      <div
        className={clsx(
          'tracking-widest w-max uppercase overflow-ellipsis max-w-full',
          sizedClassName,
          bottomBorderClassName
        )}
      >
        {children}
      </div>
    </div>
  );
}
