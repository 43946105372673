import { Session, SlimVersion } from '@tensorleap/api-client';
import { useState } from 'react';
import { useVersionControl } from '../../core/VersionControlContext';
import { Select } from '../../ui/atoms/Select';

export type ModelSelectorProps = {
  session?: Session;
  onChange: (session: Session, version: SlimVersion) => void;
  initialSelectedVersion: SlimVersion;
  getVersionDisabledMsg?: (version: SlimVersion) => string | undefined;
  onCreateAndSelectSession?: (version: SlimVersion) => void;
};

export function ModelSelector({
  onChange,
  initialSelectedVersion,
  onCreateAndSelectSession,
}: ModelSelectorProps) {
  const { versions } = useVersionControl();
  const [selectedVersion, setSelectedVersion] = useState<
    SlimVersion | undefined
  >(initialSelectedVersion);

  const handleVersionChange = (value?: string) => {
    const version = versions.find((v) => v.cid === value);
    if (!version) {
      console.error('Version not found');
      return;
    }

    setSelectedVersion(version);
    const firstSession = version.sessions[0];

    if (firstSession) {
      onChange(firstSession, version);
    } else if (onCreateAndSelectSession) {
      onCreateAndSelectSession(version);
    }
  };

  return (
    <div className="flex-1 cursor-pointer rounded border border-gray-700">
      <Select
        label="VERSION"
        options={versions}
        value={selectedVersion?.cid || ''}
        onChange={handleVersionChange}
        optionToLabel={(version) => version.notes}
        optionID="cid"
        noOptionsText="Select Model"
        className="w-full"
      />
    </div>
  );
}
