import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, ListItem, ListItemIcon, ListItemText, List } from '../ui/mui';
import { LoadProjectItem } from './LoadProjectItem';
import {
  Plus,
  FolderOpenO,
  ResourcesIcon,
  Logout,
  StopwatchIcon,
  TeamOutlined,
  Settings,
  ShieldKeyhole,
} from '../ui/icons';
import { useAuth } from '../auth/AuthContext';
import { NewProjectDialog } from '../actions-dialog/ProjectDialogs';
import { useFreemium } from '../trial/useFreemium';
import { URLS_ENUM } from '../url/url-builder';
import { useCliTokenDialog } from '../ui/GenerateCliLogin';

export interface MenuItemData {
  title: string;
  icon: JSX.Element;
  onClick?: (title: string) => void;
  hasSubmenu?: boolean;
}

export function LeftMenu(): JSX.Element {
  const history = useHistory();
  const { logout, user } = useAuth();
  const {
    isSingleUser,
    upgradeDialog,
    upgradeDialogState: { setState: upgradeDialogSetIsOpen },
  } = useFreemium();

  const { openCliTokenDialog, cliTokenDialog } = useCliTokenDialog();

  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false);

  const menuItems = useMemo<MenuItemData[]>(() => {
    const menuOptions = [
      {
        title: 'NEW PROJECT',
        icon: <Plus />,
        onClick: () => setIsNewProjectDialogOpen(true),
      },
      { title: 'OPEN PROJECT', icon: <FolderOpenO />, hasSubmenu: true },
      {
        title: 'RESOURCES MANAGEMENT',
        icon: <ResourcesIcon />,
        onClick: () => history.push(URLS_ENUM.ASSETS),
      },
      {
        title: 'SETTINGS',
        icon: <Settings />,
        onClick: () => history.push(URLS_ENUM.SETTINGS),
      },
      {
        title: 'CLI TOKEN',
        icon: <ShieldKeyhole />,
        onClick: openCliTokenDialog,
      },
    ];
    if (user?.role === 'admin') {
      menuOptions.push({
        title: 'TEAM MANAGEMENT',
        icon: <TeamOutlined />,
        onClick: () => history.push(URLS_ENUM.TEAM_MANAGEMENT),
      });
    }
    if (isSingleUser) {
      menuOptions.push({
        title: 'EXTEND TRIAL',
        icon: <StopwatchIcon />,
        onClick: () => upgradeDialogSetIsOpen(true),
      });
    }
    return menuOptions;
  }, [
    history,
    isSingleUser,
    upgradeDialogSetIsOpen,
    openCliTokenDialog,
    user?.role,
  ]);

  return (
    <>
      {upgradeDialog}
      {cliTokenDialog}
      <div className="flex h-full flex-col">
        <List className="flex-1">
          {menuItems.map(({ title, icon, onClick, hasSubmenu }, index) => {
            const hasDivider = index < menuItems.length - 1;
            const itemProps = { key: index, title, icon, onClick, hasDivider };

            return hasSubmenu ? (
              <LoadProjectItem {...itemProps} />
            ) : (
              <MenuItem {...itemProps} />
            );
          })}
        </List>
        <>
          <Divider />
          <List>
            <MenuItem
              title="LOG OUT"
              icon={<Logout />}
              onClick={logout}
              hasDivider
            />
          </List>
        </>
      </div>
      {isNewProjectDialogOpen && (
        <NewProjectDialog
          isOpen={isNewProjectDialogOpen}
          onClose={() => setIsNewProjectDialogOpen(false)}
        />
      )}
    </>
  );
}

export function MenuItem({
  title,
  icon,
  onClick = () => undefined,
  hasDivider,
}: MenuItemData & { hasDivider: boolean }): JSX.Element {
  return (
    <>
      <ListItem component="li" button onClick={() => onClick(title)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
      {hasDivider && <Divider />}
    </>
  );
}
