import {
  createContext,
  useContext,
  FC,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import api from './api-client';
import { useMergedObject } from './useMergedObject';
import {
  HandleDashboardStateChangeProps,
  SessionRunState,
} from '../ui/ProjectPageLoader';
import {
  calcLocalStorageProjectStateKey,
  getStringValueFromQueryParams,
  PROJECT_STATE_KEY,
  setQueryParam,
  STATE_KEYS,
} from '../url/url-builder';
import { useLocation, useHistory } from 'react-router';
import { cloneDeep } from 'lodash';
export type DashboardState = Partial<Record<STATE_KEYS, unknown>>;

export interface ProjectState {
  dashboards?: Record<string, DashboardState>;
  selectedSessionRuns?: SessionRunState[];
}

export interface ProjectURLStateContextInterface {
  projectState: ProjectState;
  handleDashboardStateChange: (
    _: HandleDashboardStateChangeProps
  ) => Promise<void>;
  handleSelectedSessionRunsChange: (
    selectedSessionRunIds: SessionRunState[]
  ) => Promise<void>;
  isLoading: boolean;
}

export const ProjectURLStateContext = createContext<ProjectURLStateContextInterface>(
  {
    projectState: {},
    handleDashboardStateChange: async () => undefined,
    handleSelectedSessionRunsChange: async () => undefined,
    isLoading: true,
  }
);

export interface ProjectURLStateProviderProps {
  projectId: string;
}

export const ProjectURLStateProvider: FC<ProjectURLStateProviderProps> = ({
  projectId,
  children,
}) => {
  const { search } = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const urlStateDigest = useMemo(
    () => getStringValueFromQueryParams(search, PROJECT_STATE_KEY),
    [search]
  );

  const [parsedState, setParsedState] = useState<ProjectState | undefined>();

  useEffect(() => {
    if (parsedState !== undefined) {
      return;
    }

    async function fetchState(projectId: string, digest: string) {
      const { state } = await api.getState({ projectId, digest });
      return JSON.parse(state) as ProjectState;
    }

    if (urlStateDigest) {
      fetchState(projectId, urlStateDigest)
        .then((projectState) => {
          setParsedState(projectState);
        })
        .catch((e) => {
          console.error('failed to fetch state', e);
          setParsedState(undefined);
        });
    } else {
      setParsedState(undefined);
    }
    setIsLoading(false);
  }, [parsedState, projectId, urlStateDigest]);

  const handleProjectStateChange = useCallback(
    async (newState: ProjectState) => {
      setParsedState(newState);

      const { digest } = await api.upsertState({
        projectId,
        state: JSON.stringify(newState),
      });

      const localStorageProjectStateKey = calcLocalStorageProjectStateKey(
        projectId
      );

      localStorage.setItem(localStorageProjectStateKey, digest);
      history.push({
        search: setQueryParam(search, PROJECT_STATE_KEY, digest),
      });
    },
    [history, projectId, search]
  );

  const handleDashboardStateChange = useCallback(
    async ({ newValues, dashboardId }: HandleDashboardStateChangeProps) => {
      const newDashboardState = newValues.reduce(
        (acc, { stateKey, value }) => {
          acc[stateKey as STATE_KEYS] = value;
          return acc;
        },
        { ...(parsedState?.dashboards?.[dashboardId] || {}) }
      );

      const newProjectState = {
        ...parsedState,
        dashboards: cloneDeep({
          ...parsedState?.dashboards,
          [dashboardId]: newDashboardState,
        }),
      };

      handleProjectStateChange(newProjectState);
    },
    [handleProjectStateChange, parsedState]
  );

  const handleSelectedSessionRunsChange = useCallback(
    async (selectedSessionRunIds: SessionRunState[]) => {
      const newProjectState = {
        ...parsedState,
        selectedSessionRuns: selectedSessionRunIds,
      };

      handleProjectStateChange(newProjectState);
    },
    [handleProjectStateChange, parsedState]
  );

  const projectState = parsedState || ({} as ProjectState);

  const value = useMergedObject({
    projectState,
    handleDashboardStateChange,
    handleSelectedSessionRunsChange,
    isLoading,
  });

  return (
    <ProjectURLStateContext.Provider value={value}>
      {children}
    </ProjectURLStateContext.Provider>
  );
};

export const useProjectURLState: () => ProjectURLStateContextInterface = () =>
  useContext(ProjectURLStateContext);
