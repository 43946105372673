import { Redirect, Route, useLocation } from 'react-router';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { DatasetsProvider } from '../core/DatasetsContext';
import { DashboardContextProvider } from '../dashboard/DashboardContext';
import { DashboardsPane } from '../dashboard/DashboardPane';
import { NetworkEditorPane } from '../network-editor';
import {
  SELECTED_DASHBOARD_KEY,
  URLS_ENUM,
  buildProjectUrl,
  deleteQueryParam,
  getStringValueFromQueryParams,
  setQueryParam,
  DASHBOARD_STATE_KEY,
  STATE_KEYS,
  calcLocalStorageLastProjectDashboardKey,
} from '../url/url-builder';
import { ProjectTabs } from './ProjectCardTabs';
import { PageLoader } from './molecules/PageLoader';
import { useFetchDashboards } from '../core/data-fetching/dashboards';
import { VisualizationFilter } from '../core/types/filters';

export interface stateKeyToValue {
  stateKey: STATE_KEYS;
  value: unknown;
}

export interface HandleStateChangeProps {
  newValues: stateKeyToValue[];
  dashboardId: string;
}
export function DashboardAndNetworkTabsLoader(): JSX.Element {
  const { currentProjectId, currentVersionId } = useCurrentProject();

  const { search, pathname } = useLocation();

  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const dashboardIdFromUrl = getStringValueFromQueryParams(
    search,
    SELECTED_DASHBOARD_KEY
  );

  const { dashboards, isLoading } = useFetchDashboards({
    projectId: currentProjectId,
  });

  if (isLoading) {
    return <PageLoader />;
  }

  const lastDashboardIdFromLocalStorage = localStorage.getItem(
    calcLocalStorageLastProjectDashboardKey(currentProjectId)
  );

  if (dashboardIdFromUrl === undefined && dashboards.length) {
    const dashboard =
      dashboards.find(({ cid }) => cid === lastDashboardIdFromLocalStorage) ||
      dashboards[0];

    const newSearch = setQueryParam(
      search,
      SELECTED_DASHBOARD_KEY,
      dashboard.cid
    );

    return (
      <Redirect
        to={{
          pathname,
          search: newSearch,
        }}
      />
    );
  }

  if (
    dashboardIdFromUrl !== undefined &&
    !dashboards.some((d) => d.cid === dashboardIdFromUrl)
  ) {
    const newSearch = deleteQueryParam(
      deleteQueryParam(search, SELECTED_DASHBOARD_KEY),
      DASHBOARD_STATE_KEY
    );
    console.warn('Dashboard not found, redirecting...', newSearch);
    return (
      <Redirect
        to={{
          pathname,
          search: newSearch,
        }}
      />
    );
  }

  return (
    <DashboardAndNetworkTabsContent
      dashboardId={dashboardIdFromUrl}
      key={dashboardIdFromUrl}
    />
  );
}

export type LocalFiltersMap = Record<string, VisualizationFilter[]>;

export interface DashboardAndNetworkTabsContentProps {
  dashboardId?: string;
}

export function DashboardAndNetworkTabsContent({
  dashboardId,
}: DashboardAndNetworkTabsContentProps): JSX.Element {
  const { currentProjectId, currentVersionId } = useCurrentProject();

  if (!currentProjectId || !currentVersionId) {
    throw new Error('no current-project/version, we shouldnt get here');
  }

  const projectUrl = buildProjectUrl(currentProjectId);

  return (
    <DashboardContextProvider dashboardId={dashboardId} key={dashboardId}>
      <Route path={`${projectUrl}/*${URLS_ENUM.TAB}/${ProjectTabs.Dashboard}`}>
        <DashboardsPane />
      </Route>
      <Route path={[`${projectUrl}/*${URLS_ENUM.TAB}/${ProjectTabs.Network}`]}>
        <DatasetsProvider>
          <NetworkEditorPane />
        </DatasetsProvider>
      </Route>
    </DashboardContextProvider>
  );
}
