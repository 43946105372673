import React, { PropsWithChildren, useMemo } from 'react';
import { Heatmap } from '@tensorleap/api-client';
import {
  AreaChart,
  Area,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from './mui';
import { range, scaleLinear } from 'd3';
import { interpolateArray, useDynamicLegend } from './charts/utils';
import {
  AXIS_LINE_STROKE_COLOR,
  DOMAIN_BY_DATA,
  LEGEND_STYLE,
  ticket,
  TICK_LINE_SIZE,
} from './charts/chart.utils';
import { useFeatureFlags } from '../core/FeatureFlagsContext';
import { useToggle } from '../core/useToggle';
import { CollapseIcon, ExpandIcon } from './icons';
import { Button } from './atoms/Button';
import clsx from 'clsx';
import { TransformComponent } from 'react-zoom-pan-pinch';

export type MultiLineGraphProps = {
  data: number[][];
  heatmap?: Heatmap;
  previewMode?: boolean;
  className?: string;
  xLabel?: string;
  yLabel?: string;
  xRange?: [number, number];
  lineGraphClassName?: string;
};

export function MultiLineGraphZoomable(
  props: MultiLineGraphProps
): JSX.Element {
  return (
    <TransformComponent
      contentStyle={{
        height: '100%',
        width: '100%',
      }}
      wrapperStyle={{
        height: '100%',
        width: '100%',
      }}
    >
      <MultiLineGraph {...props} />;
    </TransformComponent>
  );
}

export function MultiLineGraph({
  data,
  heatmap,
  previewMode = false,
  className,
  lineGraphClassName,
  xLabel,
  yLabel,
  xRange,
}: MultiLineGraphProps): JSX.Element {
  const { mappedData, lines, xDomain } = useNormalizeData(data, xRange);
  const [splitLines, toggleSplitByLines] = useToggle(false);

  const {
    featureFlags: { noAnimation: noAnimationFlag },
  } = useFeatureFlags();

  const heatMapComp = useMemo(
    () =>
      heatmap?.body && (
        <HeatmapArea
          noAnimation={previewMode || noAnimationFlag}
          heatmap={heatmap.body}
        />
      ),
    [heatmap?.body, previewMode, noAnimationFlag]
  );

  return (
    <div
      className={clsx(
        'h-full w-full flex flex-col min-h-0 overflow-hidden',
        className
      )}
    >
      {lines.length > 0 && !previewMode && (
        <div className="flex flex-row justify-end">
          <Button
            variant="action-icon"
            className="h-9 w-9"
            onClick={toggleSplitByLines}
          >
            {splitLines ? <CollapseIcon /> : <ExpandIcon />}
          </Button>
        </div>
      )}
      {splitLines ? (
        <div className="flex-1 flex flex-col overflow-y-auto overflow-x-hidden">
          {lines.map((line, index) => (
            <LineGraph
              key={index}
              mappedData={mappedData}
              lines={[line]}
              xDomain={xDomain}
              xLabel={xLabel}
              yLabel={yLabel}
              previewMode={previewMode}
              className={lineGraphClassName}
              noAnimation={previewMode || noAnimationFlag}
            >
              {heatMapComp}
            </LineGraph>
          ))}
        </div>
      ) : (
        <LineGraph
          mappedData={mappedData}
          lines={lines}
          xLabel={xLabel}
          yLabel={yLabel}
          xDomain={xDomain}
          previewMode={previewMode}
          className={lineGraphClassName}
          noAnimation={previewMode || noAnimationFlag}
        >
          {heatMapComp}
        </LineGraph>
      )}
    </div>
  );
}

type NormalizedData = {
  mappedData: unknown[];
  lines: {
    dataKey: string;
    stroke: string;
  }[];
  xDomain: number[];
};

export type LineGraphProps = NormalizedData & {
  noAnimation: boolean;
  className?: string;
  heatmap?: Heatmap;
  xLabel?: string;
  yLabel?: string;
  previewMode?: boolean;
};

const MARGIN = {
  top: 10,
  right: 20,
  left: 0,
  bottom: 5,
} as const;
const MAX_LINES = 20;
const MAX_POINTS = 1500;
const GRAPH_LABEL_MARGIN = 30;

export const LineGraph = React.memo<PropsWithChildren<LineGraphProps>>(
  ({
    mappedData,
    lines,
    xDomain,
    xLabel,
    yLabel,
    children,
    className,
    noAnimation,
    previewMode = false,
  }) => {
    const {
      hoverDataKey,
      hides,
      handleLegendClick,
      handleLegendMouseOver,
      handleLegendMouseLeave,
    } = useDynamicLegend();

    const { margin, xStyledLabel, yStyledLabel } = useMemo(
      () => ({
        margin: {
          ...MARGIN,
          bottom: xLabel ? GRAPH_LABEL_MARGIN : MARGIN.bottom,
          left: yLabel ? GRAPH_LABEL_MARGIN : MARGIN.left,
        },
        xStyledLabel: xLabel
          ? {
              value: xLabel,
              position: 'bottom',
              offset: 0,
              fill: '#ccc',
            }
          : undefined,
        yStyledLabel: yLabel
          ? {
              value: yLabel,
              angle: -90,
              position: 'left',
              offset: 10,
              fill: '#ccc',
            }
          : undefined,
      }),
      [xLabel, yLabel]
    );

    return (
      <div
        className={clsx(
          'flex-1 w-full h-full relative',
          !previewMode && 'min-h-[144px]',
          className
        )}
      >
        {children}
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={mappedData}
            margin={!previewMode ? margin : undefined}
          >
            {!previewMode && (
              <>
                <XAxis
                  type="number"
                  dataKey="X"
                  domain={xDomain}
                  label={xStyledLabel}
                  reversed={xDomain[0] > xDomain[1]}
                  tickSize={TICK_LINE_SIZE}
                  angle={-45}
                  tick={ticket}
                  stroke={AXIS_LINE_STROKE_COLOR}
                />
                <YAxis
                  domain={DOMAIN_BY_DATA}
                  tickSize={TICK_LINE_SIZE}
                  tick={ticket}
                  label={yStyledLabel}
                  stroke={AXIS_LINE_STROKE_COLOR}
                />
                <Tooltip wrapperClassName="!bg-gray-800 rounded !border-none shadow" />
                <Legend
                  verticalAlign="top"
                  align="right"
                  wrapperStyle={LEGEND_STYLE}
                  height={28}
                  onClick={handleLegendClick as () => void}
                  onMouseOver={handleLegendMouseOver}
                  onMouseOut={handleLegendMouseLeave}
                />
              </>
            )}
            {lines.map((l) => (
              <Line
                key={l.dataKey}
                type="linear"
                hide={hides.has(l.dataKey)}
                isAnimationActive={!noAnimation}
                {...l}
                dot={false}
                strokeOpacity={hoverDataKey === l.dataKey ? 0.5 : undefined}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
);
LineGraph.displayName = 'LineGraph';

const HEATMAP_COLORS = ['red', 'lightblue'];

const HeatmapGrad = React.memo<{ name: string }>(({ name }) => {
  const scale = useMemo(
    () => scaleLinear().domain([0, HEATMAP_COLORS.length]).range([0, 100]),
    []
  );
  return (
    <linearGradient
      id={name}
      x1="0%"
      y1="0%"
      x2="0%"
      y2="90%"
      gradientUnits="userSpaceOnUse"
    >
      {HEATMAP_COLORS.map((color, i) => (
        <stop
          key={color}
          offset={`${scale(i)}%`}
          style={{ stopColor: color }}
        />
      ))}
    </linearGradient>
  );
});
HeatmapGrad.displayName = 'HeatmapDefs';

type HeatmapAreaProps = { heatmap: number[]; noAnimation?: boolean };

function HeatmapArea({ heatmap, noAnimation }: HeatmapAreaProps) {
  const interpolateHeatmap = useMemo(
    () =>
      heatmap.length > MAX_POINTS
        ? interpolateArray(heatmap, MAX_POINTS)
        : heatmap,
    [heatmap]
  );

  const mappedHeatmap = useMemo(
    () => interpolateHeatmap.map((Y, X) => ({ Y, X })),
    [interpolateHeatmap]
  );
  return (
    <div className="absolute bottom-[31px] top-[80%] right-0 left-[60px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart width={50} height={400} data={mappedHeatmap} margin={MARGIN}>
          <defs>
            <HeatmapGrad name="heatmap" />
          </defs>

          <XAxis dataKey="X" hide domain={DOMAIN_BY_DATA} type="number" />
          <YAxis hide domain={[0, 1]} />
          <Tooltip />
          <Area
            isAnimationActive={!noAnimation}
            type="linear"
            dataKey="Y"
            fillOpacity={0.3}
            stroke="url(#heatmap)"
            fill="url(#heatmap)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

function useNormalizeData(
  data: number[][],
  xRange?: [number, number]
): NormalizedData {
  const theme = useTheme();
  return useMemo(() => {
    const interpolatedData =
      data.length > MAX_POINTS ? interpolateArray(data, MAX_POINTS) : data;

    const indexDomain = scaleLinear().domain([0, interpolatedData.length - 1]);
    let mapIndexToX: (i: number) => number;
    let xDomain: number[];
    if (xRange) {
      const scale = indexDomain.range(xRange);
      mapIndexToX = (i: number) => scale(i);
      xDomain = xRange;
    } else {
      const scale = indexDomain.range([1, data.length]);
      mapIndexToX = (i: number) => Math.floor(scale(i));
      xDomain = [1, data.length || 1];
    }

    const totalCount = data[0]?.length || 0;
    const indexes = range(totalCount > MAX_LINES ? MAX_LINES : totalCount);

    const mappedData = interpolatedData.map((a, index) =>
      Object.fromEntries([
        ['X', mapIndexToX(index)],
        ...indexes.map((i) => [String(i), a[i]]),
      ])
    );
    const lines = indexes.map((i) => ({
      dataKey: String(i),
      stroke: theme.palette.lineGraph[i],
    }));
    console.log(xDomain);
    return { mappedData, lines, xDomain };
  }, [data, theme, xRange]);
}
