import {
  CompositeVizData,
  GradsAnalysis,
  GradsItem,
  VisData,
  CompositeVizItem,
  DataTypeEnum,
  SampleAnalysisAlgo,
} from '@tensorleap/api-client';
import { FormControl, Tooltip } from '../../ui/mui';
import { DisplayVisualizedItemData } from '../dashlet/VisualizationDisplay/VisData';
import { Select } from '../../ui/atoms/Select';
import { first, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  DepthSlideSelector,
  DepthSlideSelectorProps,
} from './DepthSlideSelector';
import { DepthIcon } from '../../ui/icons';
import {
  isGradAnalysisData,
  isImageHeatmapData,
} from '../dashlet/VisualizationDisplay/visDataHelpers';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';
import { SampleAnalysisAlgoToString } from '../utils';

interface HeatmapMenuProps {
  menus: HeatMapMenuElementProps[];
}
function HeatmapMenu({ menus }: HeatmapMenuProps): JSX.Element {
  return (
    <div className="flex w-full justify-center items-center pt-1">
      <div className="grid grid-cols-2">
        {menus.map((menu, index) => (
          <div key={index} className="flex justify-center items-center">
            <HeatMapMenuElement
              menuName={menu.menuName}
              menuOptions={menu.menuOptions}
              defaultValue={menu.defaultValue}
              onChange={menu.onChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

interface HeatMapMenuElementProps {
  menuName: string;
  menuOptions: string[];
  defaultValue: string;
  onChange: (value: string) => void;
}

function HeatMapMenuElement({
  menuName,
  menuOptions,
  defaultValue,
  onChange,
}: HeatMapMenuElementProps): JSX.Element {
  return (
    <FormControl className="w-3/4">
      <Select
        ultraSmall
        value={defaultValue}
        options={menuOptions}
        label={menuName}
        onChange={(value) => onChange(value || first(menuOptions) || '')}
        optionToLabel={(o) => o.toString()}
        small
      />
    </FormControl>
  );
}

export interface HeatMapElementProps {
  selectedVisData?: VisData;
  depthSlideSelectorProps?: DepthSlideSelectorProps;
  menus: HeatMapMenuElementProps[];
  algo?: SampleAnalysisAlgo;
}

export function HeatMapElement({
  selectedVisData,
  depthSlideSelectorProps,
  menus,
  algo,
}: HeatMapElementProps): JSX.Element {
  const visDataWithBlob: VisData | undefined = useMemo(() => {
    const isHeatmap = isImageHeatmapData(selectedVisData);
    if (!isHeatmap || (isHeatmap && !!selectedVisData.heatmap_blob)) {
      return selectedVisData;
    }
    return undefined;
  }, [selectedVisData]);

  return (
    <div
      className="flex flex-col h-full w-full items-start relative overflow-hidden"
      id={TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VISUALIZED_ITEM_ID}
    >
      <div className="flex flex-1 w-full h-full items-start relative overflow-hidden">
        {visDataWithBlob &&
          DisplayVisualizedItemData({
            data: visDataWithBlob,
          })}
      </div>

      <HeatmapMenu menus={menus} />

      <div className="flex w-full">
        <DepthSelector depthSlideSelectorProps={depthSlideSelectorProps} />
        {algo && (
          <div>
            <Tooltip
              title={`Created using ${SampleAnalysisAlgoToString(
                algo
              )} algorithm`}
            >
              <span className="text-gray-400 text-xs mr-2">
                {'@' + SampleAnalysisAlgoToString(algo).replaceAll(' ', '')}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}

interface DepthSelectorProps {
  depthSlideSelectorProps?: DepthSlideSelectorProps;
}
function DepthSelector({
  depthSlideSelectorProps,
}: DepthSelectorProps): JSX.Element {
  const hasDepths = useMemo(() => {
    return depthSlideSelectorProps?.gradItems.some((item) => !!item.depth);
  }, [depthSlideSelectorProps?.gradItems]);

  return depthSlideSelectorProps && hasDepths ? (
    <div
      className="flex flex-row w-full h-fit gap-3 justify-center px-2 py-1"
      id={TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DEPTH_SELECTOR_ID}
    >
      <Tooltip title="Depth">
        <div className="flex p-0 m-0 h-fit">
          <DepthIcon className="h-4 w-4 text-gray-300" />
        </div>
      </Tooltip>
      <DepthSlideSelector {...depthSlideSelectorProps} className="mt-1" />
    </div>
  ) : (
    <></>
  );
}

export interface DisplayAnalysisHeatMapProps {
  items: GradsItem[] | CompositeVizItem[];
  type: DataTypeEnum;
  algo?: SampleAnalysisAlgo;
}

export function DisplayAnalysisHeatMap({
  items,
  type,
  algo,
}: DisplayAnalysisHeatMapProps): JSX.Element {
  const labels = uniq(items.map((gradsItem) => gradsItem.label));
  const connectionsNames = uniq(
    items.map((gradsItem) => gradsItem.connection_name)
  );

  const [selectedLabel, setSelectedLabel] = useState<string>(
    first(labels) || ''
  );
  const [selectedConnectionName, setSelectedConnectionName] = useState<string>(
    first(connectionsNames) || ''
  );

  const validLabel = useMemo(
    () =>
      labels.includes(selectedLabel) ? selectedLabel : first(labels) || '',
    [labels, selectedLabel]
  );
  useEffect(() => {
    if (validLabel !== selectedLabel) {
      setSelectedLabel(validLabel);
    }
  }, [selectedLabel, setSelectedLabel, validLabel]);

  const validConnection = useMemo(
    () =>
      connectionsNames.includes(selectedConnectionName)
        ? selectedConnectionName
        : first(connectionsNames) || '',
    [connectionsNames, selectedConnectionName]
  );
  useEffect(() => {
    if (validConnection !== selectedConnectionName) {
      setSelectedConnectionName(validConnection);
    }
  }, [selectedConnectionName, setSelectedConnectionName, validConnection]);

  const [selectedDepth, setSelectedDepth] = useState<number>();

  const filteredGradItems = useMemo(() => {
    setSelectedDepth(undefined);
    return (items as (GradsItem | CompositeVizItem)[]).filter(
      (gradItem) =>
        gradItem.label === validLabel &&
        gradItem.connection_name === validConnection
    );
  }, [items, validConnection, validLabel]);

  const selectedGradsItem = useMemo(() => {
    if (!selectedDepth || type !== 'grad_analysis')
      return first(filteredGradItems);
    return filteredGradItems.find(
      (item) =>
        !!(item as GradsItem).depth &&
        (item as GradsItem).depth === selectedDepth
    );
  }, [filteredGradItems, selectedDepth, type]);

  const visDataItems = useMemo(() => {
    if (type === 'grad_analysis') {
      return (selectedGradsItem as GradsItem)?.grads;
    }
    return (selectedGradsItem as CompositeVizItem)?.data;
  }, [selectedGradsItem, type]);

  const depthSlideSelectorProps = useMemo(() => {
    if (type === 'grad_analysis') {
      const props: DepthSlideSelectorProps = {
        gradItems: filteredGradItems as GradsItem[],
        value: selectedDepth,
        setValue: setSelectedDepth,
      };
      return props;
    }
    return undefined;
  }, [filteredGradItems, selectedDepth, type]);

  const menus = useMemo(() => {
    const menuOptions: HeatMapMenuElementProps[] = [];
    if (labels.filter((x) => x).length > 0) {
      menuOptions.push({
        menuName: 'Label',
        menuOptions: labels,
        defaultValue: selectedLabel,
        onChange: setSelectedLabel,
      });
    }
    if (connectionsNames.filter((x) => x).length > 0) {
      menuOptions.push({
        menuName: 'Connection',
        menuOptions: connectionsNames,
        defaultValue: selectedConnectionName,
        onChange: setSelectedConnectionName,
      });
    }
    return menuOptions;
  }, [connectionsNames, labels, selectedConnectionName, selectedLabel]);

  return (
    <HeatMapElement
      selectedVisData={visDataItems}
      depthSlideSelectorProps={depthSlideSelectorProps}
      menus={menus}
      algo={algo}
    />
  );
}

export interface DisplayHeatmapProps {
  visDataElement: GradsAnalysis | CompositeVizData;
}
export function DisplayHeatmap({
  visDataElement,
}: DisplayHeatmapProps): JSX.Element {
  const algo = isGradAnalysisData(visDataElement)
    ? visDataElement.algo
    : undefined;
  return (
    <DisplayAnalysisHeatMap
      items={visDataElement.data}
      type={visDataElement.type}
      algo={algo}
    />
  );
}
