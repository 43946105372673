import { Redirect, useHistory, useLocation } from 'react-router';
import { CurrentProjectProvider } from '../core/CurrentProjectContext';
import {
  DEFAULT_VERSION_ID,
  PROJECT_STATE_KEY,
  STATE_KEYS,
  URLS_ENUM,
  buildVersionUrl,
  calcLocalStorageProjectStateKey,
  getIdFromUrl,
  getStringValueFromQueryParams,
  setQueryParam,
  switchVersionUrl,
} from '../url/url-builder';
import { ProjectPage } from './ProjectPage';
import { PageLoader } from './molecules/PageLoader';
import { useFetchProjects } from '../core/data-fetching/projects';
import { useEffect, useMemo, useState } from 'react';
import api from '../core/api-client';
import { Project } from '@tensorleap/api-client';
import { useLocalStorage } from '../core/useLocalStorage';
import {
  ProjectURLStateProvider,
  useProjectURLState,
} from '../core/ProjectURLStateContext';

const DEFAULT_PROJECT_ID_FROM_LOCAL_STORAGE = 'defaultProjectId';
const DEFAULT_VERSION_ID_PER_PROJECT_FROM_LOCAL_STORAGE = 'defaultVersionId';

export function calcDefaultVersionIdFromLocalStorageKey(projectId: string) {
  return `${DEFAULT_VERSION_ID_PER_PROJECT_FROM_LOCAL_STORAGE}_${projectId}`;
}

export function ProjectLoader(): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const { projects } = useFetchProjects();

  const unparsedDefaultProjectId = window.localStorage.getItem(
    DEFAULT_PROJECT_ID_FROM_LOCAL_STORAGE
  );
  const defaultProjectId = unparsedDefaultProjectId
    ? JSON.parse(unparsedDefaultProjectId)
    : '';

  const [_, setDefaultProjectId] = useLocalStorage(
    DEFAULT_PROJECT_ID_FROM_LOCAL_STORAGE,
    ''
  );

  const projectIdFromUrl = getIdFromUrl(location.pathname, URLS_ENUM.PROJECT);

  const project = useMemo(
    () => projects?.find((p) => p.cid === projectIdFromUrl),
    [projects, projectIdFromUrl]
  );

  useEffect(() => {
    if (project) {
      setDefaultProjectId(project.cid);
    }
  }, [project, setDefaultProjectId]);

  useEffect(() => {
    if (projectIdFromUrl && project) {
      const urlStateDigest = getStringValueFromQueryParams(
        location.search,
        PROJECT_STATE_KEY
      );
      if (!urlStateDigest) {
        const localStorageProjectStateKey = calcLocalStorageProjectStateKey(
          projectIdFromUrl
        );
        const stateDigest = window.localStorage.getItem(
          localStorageProjectStateKey
        );
        if (stateDigest) {
          history.replace({
            search: setQueryParam(
              location.search,
              PROJECT_STATE_KEY,
              stateDigest
            ),
          });
        }
      }
    }
  }, [projectIdFromUrl, project, history, location.search]);

  if (!projectIdFromUrl) {
    if (defaultProjectId) {
      return (
        <Redirect to={buildVersionUrl(defaultProjectId, DEFAULT_VERSION_ID)} />
      );
    } else {
      return <Redirect to={URLS_ENUM.WELCOME} />;
    }
  }

  if (projects === undefined) {
    return <PageLoader />;
  }

  if (project === undefined) {
    console.error('project not found, redirecting to welcome page', {
      projectIdFromUrl,
    });
    return <Redirect to={URLS_ENUM.WELCOME} />;
  }

  return <VersionLoader project={project} key={project.cid} />;
}

interface VersionLoaderProps {
  project: Project;
}

function VersionLoader({ project }: VersionLoaderProps): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const [defaultVersionId] = useLocalStorage(
    calcDefaultVersionIdFromLocalStorageKey(project.cid),
    ''
  );

  const [versionId, setVersionId] = useState<string | undefined>();

  const [versionWasNotFound, setVersionWasNotFound] = useState(false);

  const versionIdFromUrl = getIdFromUrl(location.pathname, URLS_ENUM.VERSION);

  const { search } = useLocation();

  useEffect(() => {
    function addVersionToUrl(_projectId: string, _versionId: string) {
      history.replace({
        pathname:
          _versionId === DEFAULT_VERSION_ID
            ? switchVersionUrl(window.location.pathname, _versionId)
            : buildVersionUrl(_projectId, _versionId),
        search,
      });
    }

    async function asyncLoadVersion(_projectId: string, _versionId: string) {
      const { version } = await api.loadVersion({
        versionId: _versionId,
        projectId: _projectId,
      });
      if (!version) {
        throw new Error('version not found');
      }

      setVersionId(version.cid);
    }

    async function addLatestValidVersionToURL(_projectId: string) {
      const res = await api.getCurrentProjectVersion({
        projectId: _projectId,
      });
      if (!res) {
        throw new Error('version not found');
      }

      const versionId = res.versionId;

      addVersionToUrl(_projectId, versionId);
    }

    if (
      versionIdFromUrl === undefined ||
      versionIdFromUrl === DEFAULT_VERSION_ID
    ) {
      if (versionId) return;
      asyncLoadVersion(project.cid, defaultVersionId)
        .then(() => {
          addVersionToUrl(project.cid, defaultVersionId);
        })
        .catch(() => {
          addLatestValidVersionToURL(project.cid).catch(() => {
            console.warn('current version was not found', versionIdFromUrl);
            setVersionWasNotFound(true);
          });
        });
    } else {
      asyncLoadVersion(project.cid, versionIdFromUrl).catch(() => {
        console.warn('version from url was not found', versionIdFromUrl);
        setVersionWasNotFound(true);
      });
    }
  }, [
    defaultVersionId,
    history,
    project.cid,
    search,
    versionId,
    versionIdFromUrl,
  ]);

  if (versionWasNotFound) {
    return <Redirect to={URLS_ENUM.WELCOME} />;
  }

  if (versionId === undefined) {
    return <PageLoader />;
  }

  return (
    <ProjectURLStateProvider projectId={project.cid} key={project.cid}>
      <ProjectStateLoader project={project} versionId={versionId} />
    </ProjectURLStateProvider>
  );
}

export interface SessionRunState {
  id: string;
  isVisibile: boolean;
}

interface ProjectStateLoaderProps {
  project: Project;
  versionId: string;
}

function ProjectStateLoader({
  project,
  versionId,
}: ProjectStateLoaderProps): JSX.Element {
  const { isLoading: isLoadingProjectState } = useProjectURLState();

  if (isLoadingProjectState) {
    return <PageLoader />;
  }

  return (
    <CurrentProjectProvider
      project={project}
      versionId={versionId}
      key={project.cid}
    >
      <ProjectPage />
    </CurrentProjectProvider>
  );
}

export interface StateKeyToValue {
  stateKey: STATE_KEYS;
  value: unknown;
}

export interface HandleDashboardStateChangeProps {
  newValues: StateKeyToValue[];
  dashboardId: string;
}
