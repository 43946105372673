import React, { forwardRef } from 'react';
import clsx from 'clsx';

export interface TruncateProps {
  value: string;
  className?: string;
  numOfCharsToShowAtEnd?: number;
  capitalize?: boolean;
}

export const Truncate = forwardRef<HTMLDivElement, TruncateProps>(
  (
    {
      value,
      numOfCharsToShowAtEnd = 5,
      className,
      capitalize = false,
    }: TruncateProps,
    ref
  ) => {
    if (value.length < 10)
      return (
        <span
          className={clsx(
            className,
            'truncate whitespace-nowrap',
            capitalize && 'capitalize'
          )}
        >
          {value}
        </span>
      );

    const sliceIndex = value.length - numOfCharsToShowAtEnd;
    const start = value.slice(0, sliceIndex);
    const end = value.slice(sliceIndex);

    return (
      <div
        ref={ref}
        className={clsx(
          className,
          'w-full gap-0 flex flex-row items-start whitespace-nowrap'
        )}
      >
        <span className={clsx(capitalize && 'capitalize', 'truncate')}>
          {start}
        </span>
        <span className="">{end}</span>
      </div>
    );
  }
);

Truncate.displayName = 'Truncate';
