import { useCallback, useEffect, useState } from 'react';
import { useMapStoredProjectImgUrl } from '../../core/useProjectStorage';
import clsx from 'clsx';
import { interpolateTurbo, scaleSequential } from 'd3';
import { HeatmapSliders } from '../../dashboard/SampleAnalysisView/HeatmapSliders';
import { useFetchFlatbuffers } from '../../core/data-fetching/fetch-flat-buffer';
import * as flatbuffers from 'flatbuffers';
import { HeatmapData } from './flatbuffer/heatmap-data';
import { TransformComponent } from 'react-zoom-pan-pinch';
import { resizeMatrix } from './resizeMetrix';
import { useDebounce } from '../../core/useDebounce';
import * as d3 from 'd3';

interface ImageWithHeatmapProps {
  srcBlob: string;
  heatmapBlob: string;
  showSliders?: boolean;
  allowZoom?: boolean;
  className?: string;
}

export function ImageWithHeatmap({
  srcBlob,
  heatmapBlob,
  showSliders = true,
  className,
  allowZoom,
}: ImageWithHeatmapProps): JSX.Element {
  const [clipping, setClipping] = useState<number[] | undefined>([0.05, 0.95]);
  const [opacity, setOpacity] = useState<number | undefined>(0.75);
  const opacitySlideSelectorProps = {
    value: opacity,
    setValue: setOpacity,
  };
  const clippingSlideSelectorProps = {
    value: clipping,
    setValue: setClipping,
  };

  const mappedImgSrc = useMapStoredProjectImgUrl(srcBlob);
  const mappedHeatmapBlob = useMapStoredProjectImgUrl(heatmapBlob);

  const postProcessFlatbuffer = (
    buffer: flatbuffers.ByteBuffer
  ): number[][] => {
    const heatmapData = HeatmapData.getRootAsHeatmapData(buffer);
    const width = heatmapData.width();
    const height = heatmapData.height();
    const flatArray = heatmapData.dataArray();
    if (!flatArray) {
      return [];
    }

    if (!flatArray || flatArray.length !== width * height) {
      console.error('Heatmap data length mismatch with width and height');
    }

    if (width > 10000 || height > 10000) {
      console.error('Heatmap dimensions are unexpectedly large');
    }

    const heatmap = new Array(height)
      .fill(null)
      .map(() => new Array(width).fill(0));

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        heatmap[y][x] = flatArray[y * width + x];
      }
    }
    return heatmap;
  };

  const { data: heatmap } = useFetchFlatbuffers<number[][]>({
    url: mappedHeatmapBlob,
    postProcess: postProcessFlatbuffer,
  });

  const [canvasUrl, setCanvasUrl] = useState<string | null>(null);

  const updateHeatmapVisualization = useCallback(() => {
    const hasNoData =
      !srcBlob || !heatmap || !heatmap.length || !heatmap[0].length;
    if (hasNoData) return;

    let minValue = Infinity;
    let maxValue = -Infinity;

    for (let y = 0; y < heatmap.length; y++) {
      for (let x = 0; x < heatmap[y].length; x++) {
        const value = heatmap[y][x];
        if (value < minValue) minValue = value;
        if (value > maxValue) maxValue = value;
      }
    }

    if (clipping) {
      const [minClip, maxClip] = clipping;
      const range = maxValue - minValue;
      const clippedMinValue = minValue + range * minClip;
      const clippedMaxValue = minValue + range * maxClip;

      minValue = clippedMinValue;
      maxValue = clippedMaxValue;
    }

    const colorScale = scaleSequential(interpolateTurbo).domain([0, 1]);

    const img = new Image();
    img.onload = () => {
      const resizeHeatmap = resizeMatrix(heatmap, img.height, img.width);

      const normalizedHeatmap = new Array(resizeHeatmap.length);
      for (let y = 0; y < resizeHeatmap.length; y++) {
        normalizedHeatmap[y] = new Array(resizeHeatmap[y].length);
        for (let x = 0; x < resizeHeatmap[y].length; x++) {
          const value = resizeHeatmap[y][x];
          const clippedValue = Math.min(Math.max(value, minValue), maxValue);
          normalizedHeatmap[y][x] =
            (clippedValue - minValue) / (maxValue - minValue);
        }
      }
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      canvas.width = img.width;
      canvas.height = img.height;

      // Optimize using ImageData
      const imgData = ctx.createImageData(canvas.width, canvas.height);
      const pixels = imgData.data;

      for (let y = 0; y < normalizedHeatmap.length; y++) {
        for (let x = 0; x < normalizedHeatmap[y].length; x++) {
          const value = normalizedHeatmap[y][x];
          const color = d3.rgb(colorScale(value));

          const index = (y * canvas.width + x) * 4;
          pixels[index] = color.r; // Red
          pixels[index + 1] = color.g; // Green
          pixels[index + 2] = color.b; // Blue
          pixels[index + 3] = 255; // Alpha
        }
      }

      ctx.putImageData(imgData, 0, 0); // Batch update canvas pixels
      setCanvasUrl(canvas.toDataURL());
    };
    img.src = mappedImgSrc || '';
  }, [clipping, heatmap, mappedImgSrc, srcBlob]);

  const debouncedUpdateHeatmapVisualization = useDebounce(
    updateHeatmapVisualization,
    100
  );

  useEffect(() => {
    debouncedUpdateHeatmapVisualization();
  }, [heatmap, mappedImgSrc, clipping, debouncedUpdateHeatmapVisualization]);

  if (!srcBlob) {
    return <></>;
  }
  return (
    <div className="flex flex-col items-start w-full h-full">
      {showSliders && (
        <HeatmapSliders
          opacitySlideSelectorProps={opacitySlideSelectorProps}
          clippingSlideSelectorProps={clippingSlideSelectorProps}
        />
      )}
      <div className={clsx('relative h-full w-full', className)}>
        {allowZoom ? (
          <TransformComponent
            contentStyle={{
              height: '100%',
              width: '100%',
            }}
            wrapperStyle={{
              height: '100%',
              width: '100%',
            }}
          >
            <Content
              mappedImgSrc={mappedImgSrc}
              canvasUrl={canvasUrl}
              opacity={opacity}
            />
          </TransformComponent>
        ) : (
          <Content
            mappedImgSrc={mappedImgSrc}
            canvasUrl={canvasUrl}
            opacity={opacity}
          />
        )}
      </div>
    </div>
  );
}

interface ContentProps {
  mappedImgSrc: string;
  canvasUrl: string | null;
  opacity?: number;
}

function Content({ mappedImgSrc, canvasUrl, opacity }: ContentProps) {
  return (
    <>
      <img
        src={mappedImgSrc}
        className="h-full w-fit m-auto object-contain min-h-0"
        alt="Heatmap Background"
      />
      {canvasUrl && (
        <div
          className="absolute top-0 left-0 right-0 bottom-0"
          style={{
            backgroundImage: `url(${canvasUrl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity,
          }}
        ></div>
      )}
    </>
  );
}
